import { useQuery } from '@tanstack/react-query';
import { getPropertyGroups } from 'src/Query';

/**
 * @param {string} propertyGroupName name of the group to return
 * @returns undefined if the groups are still loading (or error loading the groups), otherwise the requested group as an array of uuids
 */

function useMetaPropertyGroup(propertyGroupName) {
  const {
    data: metaPropertyGroups,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['property-group'],
    queryFn: () => getPropertyGroups()
  });

  if (!metaPropertyGroups) return undefined;

  return (
    metaPropertyGroups.find((o) => o.name === propertyGroupName)
      ?.meta_properties || []
  );
}

export default useMetaPropertyGroup;
