import { Badge, Button, Col, Collapse, Row, Switch } from 'antd';
import React, { useMemo } from 'react';

import FormFilter from 'src/components/project/explore/filters/FormFilter';
import KpiFilter from 'src/components/project/explore/filters/KpiFilter';
import MaterialFilter from 'src/components/project/explore/filters/MaterialFilter';
import RegionFilter from 'src/components/project/explore/filters/RegionFilter';
import CapacityFilter from 'src/components/project/explore/filters/CapacityFilter';
import PropertyFilter from 'src/components/project/explore/filters/PropertyFilter';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { filterOptionByRelationship } from 'src/utils/properties';
import IsCapabilityFilter from 'src/components/project/explore/filters/IsCapabilityFilter';

export default function FiltersSidebar() {
  return (
    <div className="filters-sidebar">
      <Filters />
    </div>
  );
}

function Filters() {
  const [exploreCtx, setExploreCtx] = useConciergeContextState(['explore']);

  const { filters } = exploreCtx;

  const [form] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);
  const balesMode = form === 'bales';
  const flakeMode = form === 'regrind_flakes';

  const setKpiOperator = (active) => {
    setExploreCtx({
      ...exploreCtx,
      filters: {
        ...(filters || {}),
        kpi_operator: active ? 'or' : 'and'
      }
    });
  };

  const [materialType] = useConciergeContextState([
    'explore',
    'filters',
    'type_code__in'
  ]);
  // Currently we only filter options for bale_grade property
  const filterByMaterial = useMemo(
    () => filterOptionByRelationship([materialType]),
    [materialType]
  );
  const filterByForm = useMemo(
    () => filterOptionByRelationship([form]),
    [form]
  );

  return (
    <>
      <FormFilter />
      <Row justify="space-between">
        <Col>
          <h3>Filters</h3>
        </Col>
        <Col>
          <Button
            onClick={() =>
              setExploreCtx({
                ...exploreCtx,
                filters: {},
                kpis: {}
              })
            }
            type="link"
            htmlType="button"
          >
            Reset
          </Button>
        </Col>
      </Row>
      <Collapse
        className="filters-box"
        expandIconPosition="end"
        size="small"
        items={[
          {
            key: 'material',
            label: (
              <>
                Material{' '}
                <Badge size="small" count={filters?.type_code__in ? 1 : 0} />
              </>
            ),
            children: <MaterialFilter />
          },
          {
            key: 'capacity',
            label: (
              <>
                Quantity{' '}
                <Badge size="small" count={filters?.capacity__gte ? 1 : 0} />
              </>
            ),
            children: <CapacityFilter />
          },
          {
            key: 'region',
            label: (
              <>
                Region{' '}
                <Badge
                  size="small"
                  count={filters?.region_code__in?.length || 0}
                />
              </>
            ),
            children: <RegionFilter />
          },
          ...(balesMode || flakeMode
            ? [
                {
                  key: 'bale_source',
                  label: (
                    <>
                      Source{' '}
                      <Badge
                        size="small"
                        count={filters?.properties?.bale_source?.length || 0}
                      />
                    </>
                  ),
                  children: <PropertyFilter propertyCode="bale_source" />
                },
                {
                  key: 'bale_form',
                  label: (
                    <>
                      Form{' '}
                      <Badge
                        size="small"
                        count={filters?.properties?.bale_form?.length || 0}
                      />
                    </>
                  ),
                  children: <PropertyFilter propertyCode="bale_form" />
                },
                {
                  key: 'bale_grade',
                  label: (
                    <>
                      Grade{' '}
                      <Badge
                        size="small"
                        count={filters?.properties?.bale_grade?.length || 0}
                      />
                    </>
                  ),
                  children: <PropertyFilter propertyCode="bale_grade" />
                },
                {
                  key: 'color',
                  label: (
                    <>
                      Color{' '}
                      <Badge
                        size="small"
                        count={filters?.properties?.color?.length || 0}
                      />
                    </>
                  ),
                  children: (
                    <PropertyFilter
                      filterOptions={materialType ? filterByForm : null}
                      propertyCode="color"
                    />
                  )
                },
                {
                  key: 'verified',
                  label: (
                    <>
                      Verified Suppliers{' '}
                      <Badge
                        size="small"
                        count={filters?.is_capability === false ? 1 : 0}
                      />
                    </>
                  ),
                  children: <IsCapabilityFilter />
                }
              ]
            : [])
        ]}
      />
      {!balesMode && (
        <>
          <h3>Specifications</h3>
          <KpiFilter />
          <h3>Refine Exploration</h3>
          <Collapse
            className="filters-box"
            expandIconPosition="end"
            size="small"
            items={[
              /* eslint-disable dot-notation */
              {
                key: 'grade',
                label: (
                  <>
                    Grade{' '}
                    <Badge
                      size="small"
                      count={filters?.properties?.['grade']?.length || 0}
                    />
                  </>
                ),
                children: <PropertyFilter propertyCode="grade" />
              },
              {
                key: 'processing-method',
                label: (
                  <>
                    Processing Method{' '}
                    <Badge
                      size="small"
                      count={
                        filters?.properties?.['processing-method']?.length || 0
                      }
                    />
                  </>
                ),
                children: <PropertyFilter propertyCode="processing-method" />
              },
              {
                key: 'application',
                label: (
                  <>
                    Application{' '}
                    <Badge
                      size="small"
                      count={filters?.properties?.['application']?.length || 0}
                    />
                  </>
                ),
                children: <PropertyFilter propertyCode="application" />
              },
              {
                key: 'color',
                label: (
                  <>
                    Color{' '}
                    <Badge
                      size="small"
                      count={filters?.properties?.['color']?.length || 0}
                    />
                  </>
                ),
                children: <PropertyFilter propertyCode="color" />
              },
              {
                key: 'source-type',
                label: (
                  <>
                    Source Type{' '}
                    <Badge
                      size="small"
                      count={filters?.properties?.['source-type']?.length || 0}
                    />
                  </>
                ),
                children: <PropertyFilter propertyCode="source-type" />
              },
              {
                key: 'additive',
                label: (
                  <>
                    Additives / Fillers{' '}
                    <Badge
                      size="small"
                      count={filters?.properties?.['additive']?.length || 0}
                    />
                  </>
                ),
                children: <PropertyFilter propertyCode="additive" />
              },
              {
                key: 'polymer-type',
                label: (
                  <>
                    Polymer Type{' '}
                    <Badge
                      size="small"
                      count={filters?.properties?.['polymer-type']?.length || 0}
                    />
                  </>
                ),
                children: <PropertyFilter propertyCode="polymer-type" />
              },
              {
                key: 'industry',
                label: (
                  <>
                    Industry{' '}
                    <Badge
                      size="small"
                      count={filters?.properties?.['industry']?.length || 0}
                    />
                  </>
                ),
                children: <PropertyFilter propertyCode="industry" />
              },
              {
                key: 'certs',
                label: (
                  <>
                    Certifications / Standards{' '}
                    <Badge
                      size="small"
                      count={filters?.properties?.['certs']?.length || 0}
                    />
                  </>
                ),
                children: (
                  <PropertyFilter
                    filterOptions={(opt) =>
                      ![
                        'efsa',
                        'philippines_fda',
                        'indonesia_fda',
                        'fda_lno',
                        'fda'
                      ].includes(opt.code)
                    }
                    propertyCode="certs"
                  />
                )
              },
              {
                key: 'flammability',
                label: (
                  <>
                    Flammability Rating{' '}
                    <Badge
                      size="small"
                      count={filters?.properties?.['flammability']?.length || 0}
                    />
                  </>
                ),
                children: <PropertyFilter propertyCode="flammability" />
              }
              /* eslint-enable dot-notation */
            ]}
          />
          <h3>Settings</h3>
          <Switch
            value={exploreCtx?.filters?.kpi_operator === 'or'}
            onChange={setKpiOperator}
          />
          <span className="setting-switch-label">Match any specification</span>
        </>
      )}
    </>
  );
}
