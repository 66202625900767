import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import QuantityWithUnitsInput from 'src/components/form/QuantityWithUnitsInput';

export default function PriceQuoteForm({ onValuesChange }) {
  return (
    <Form
      layout="vertical"
      onValuesChange={(_, values) => onValuesChange(values)}
    >
      <Form.Item label="Unit Price" name="price">
        <QuantityWithUnitsInput addonBefore="$" unitsFieldName="units" />
      </Form.Item>
      <Form.Item dependencies={['units']} label="Pricing Terms" name="terms">
        <Select
          options={[
            { label: 'EXW', value: 'EXW' },
            { label: 'FOB', value: 'FOB' },
            { label: 'FCA', value: 'FCA' },
            { label: 'CFR', value: 'CFR' },
            { label: 'CPT', value: 'CPT' },
            { label: 'DDP', value: 'DDP' },
            { label: 'DPU', value: 'DPU' },
            { label: 'FAS', value: 'FAS' },
            { label: 'CIF', value: 'CIF' },
            { label: "Don't know", value: '' }
          ]}
        />
      </Form.Item>
    </Form>
  );
}

PriceQuoteForm.propTypes = {
  onValuesChange: PropTypes.func
};
