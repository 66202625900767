import { ConfigProvider } from 'antd';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import './Supplier.less';

import SupplierProfile from 'src/pages/supplier/Profile';
import SupplierOnboarding from 'src/pages/supplier/Onboarding';
import SupplierInsights from 'src/pages/supplier/SupplierInsights';
import Sidebar from 'src/components/layout/Sidebar';

export default function SupplierExperience() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Roboto',
          fontFamilyCode: 'Roboto Mono',
          fontSizeHeading1: 22,
          fontSizeHeading2: 24,
          lineHeightHeading1: 28 / 22,
          lineHeightHeading2: 32 / 24,
          fontSize: 14,
          lineHeight: 22 / 14,
          margin: 12
        }
      }}
    >
      <div className="supplier-page">
        <Routes>
          <Route path="/onboarding" element={<SupplierOnboarding />} />
          <Route path="*" element={<SupplierPagesWithSidebar />} />
        </Routes>
      </div>
    </ConfigProvider>
  );
}

function SupplierPagesWithSidebar() {
  return (
    <div className="supplier-page--with-sidebar">
      <Sidebar />
      <Routes>
        <Route path="/insights/:supplierId?" element={<SupplierInsights />} />
        <Route path="/:supplierId?" element={<SupplierProfile />} />
      </Routes>
    </div>
  );
}
