import PropTypes from 'prop-types';
import numericRange from 'src/components/utils/numericRange';

export function MinMaxRangeString({ max, min, kpiUnit }) {
  return numericRange({
    min,
    max,
    rangeRender: (vMin, vMax) => `${vMin} - ${vMax} ${kpiUnit}`,
    noMaxRender: (v) => `${v} ${kpiUnit} or higher`,
    noMinRender: (v) => `${v} ${kpiUnit} or lower`,
    empty: ''
  });
}

MinMaxRangeString.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  kpiUnit: PropTypes.string
};
