import { Button, Col, Row, Typography } from 'antd';
import React, { useState } from 'react';

import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { useSummaryData } from 'src/components/project/explore/summary/helpers';
import FullScreenModal from 'src/components/layout/FullScreenModal';
import FullExploreSummary from 'src/components/project/explore/summary/FullSummary';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import SupplySummary from 'src/components/project/explore/summary/SupplySummary';
import PriceSummary from './PriceSummary';
import SpecsSummary from './SpecsSummary';

export default function ExploreSummary() {
  const [open, setOpen] = useState();

  const { isLoading } = useSummaryData();

  const loadingClass = useLoadingStateClass(isLoading);

  return (
    <>
      {open ? (
        <FullScreenModal
          width={1000}
          title="Explore Results"
          onBack={() => setOpen(false)}
          headerProps={{ right: <div /> }}
        >
          <FullExploreSummary />
        </FullScreenModal>
      ) : null}
      <ExplorePanel
        className={`explore-section explore-summary ${loadingClass}`}
        pad
      >
        <Row justify="space-between">
          <Col>
            <Typography.Title level={4}>Explore Results</Typography.Title>
          </Col>
          <Col>
            <Button
              htmlType="buton"
              onClick={() => setOpen(true)}
              type="primary"
            >
              View Full Summary
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]} align="stretch">
          <Col span="24">
            <SpecsSummary />
          </Col>
          <Col span="12">
            <SupplySummary />
          </Col>
          <Col span="12">
            <PriceSummary compact />
          </Col>
        </Row>
      </ExplorePanel>
    </>
  );
}
