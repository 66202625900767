import { Checkbox, Divider, Skeleton } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import useMetaProperty from 'src/hooks/useMetaProperty';
import { applyFilterToPropertyOptions } from 'src/utils/properties';

const NONE_VALUE = '__none__';

const translateFromNone = (options) =>
  Array.isArray(options)
    ? options.map((o) => (o === NONE_VALUE ? null : o))
    : options;
const translateToNone = (options) =>
  Array.isArray(options)
    ? options.map((o) => (o === null ? NONE_VALUE : o))
    : options;

export default function PropertyFilter({ propertyCode, filterOptions }) {
  const property = useMetaProperty(propertyCode);
  const [propertyFilter, setPropertyFilter, clearPropertyFilter] =
    useConciergeContextState([
      'explore',
      'filters',
      'properties',
      property?.code
    ]);

  const propertyFilterTranslated = useMemo(
    () => translateToNone(propertyFilter),
    [propertyFilter]
  );

  const filteredOptions = useMemo(
    () =>
      property
        ? [
            ...(filterOptions
              ? applyFilterToPropertyOptions(
                  property.options,
                  filterOptions,
                  propertyFilter
                )
              : property.options),
            { code: NONE_VALUE, value: '<None>' }
          ]
        : [],
    [filterOptions, property]
  );

  if (!property) return <Skeleton />;

  const onPropertyFilterValuesChange = (values) => {
    if (!values || values.length === 0) {
      clearPropertyFilter();
      return;
    }
    setPropertyFilter(translateFromNone(values));
  };

  return (
    <>
      <Checkbox
        onChange={(e) =>
          onPropertyFilterValuesChange(
            e.target.checked ? filteredOptions.map((o) => o.code) : []
          )
        }
        indeterminate={
          propertyFilterTranslated?.length > 0 &&
          propertyFilterTranslated?.length < filteredOptions.length
        }
        checked={propertyFilterTranslated?.length === filteredOptions.length}
      >
        Select all
      </Checkbox>
      <Divider style={{ margin: '10px 0' }} />
      <Checkbox.Group
        options={filteredOptions.map((o) => ({
          label: o.value,
          value: o.code
        }))}
        value={propertyFilterTranslated}
        onChange={onPropertyFilterValuesChange}
      />
    </>
  );
}

PropertyFilter.propTypes = {
  propertyCode: PropTypes.string,
  filterOptions: PropTypes.func
};
