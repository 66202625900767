import React, { useCallback, useState } from 'react';
import { Col, Flex, Radio, Row, Typography } from 'antd';

import ExploreSection from 'src/components/project/explore/ExploreSection';
import RegionCountsDoughnutChart from 'src/components/project/explore/charts/RegionCountsDoughnutChart';
import RegionCountsMap from 'src/components/project/explore/charts/RegionCountsMap';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';

const VisualizationOptions = {
  Records: 'r',
  Suppliers: 's'
};

export default function Location() {
  const [showBySuppliers, setShowBySuppliers] = useState(false);

  const chartTypeHandler = useCallback(
    (val) => {
      setShowBySuppliers(val.target.value === VisualizationOptions.Suppliers);
    },
    [setShowBySuppliers]
  );

  return (
    <ExploreSection
      title="Location"
      description="PCR can be sourced from any of the seven regions represented in our database: North America, Central America, South America, Europe, Asia, Africa, and Oceania. Add more regions to your exploration to expand sourcing opportunities."
    >
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <ExplorePanel pad={12} className="doughnut-chart-container p-16">
            <Flex
              align="center"
              justify="space-between"
              style={{ marginBottom: '16px' }}
            >
              <Typography.Text style={{ fontSize: 16 }} strong>
                Availability
              </Typography.Text>

              <Radio.Group
                defaultValue={VisualizationOptions.Records}
                onChange={chartTypeHandler}
                size="small"
              >
                <Radio.Button value={VisualizationOptions.Records}>
                  Records
                </Radio.Button>
                <Radio.Button value={VisualizationOptions.Suppliers}>
                  Suppliers
                </Radio.Button>
              </Radio.Group>
            </Flex>

            <RegionCountsDoughnutChart showBySuppliers={showBySuppliers} />
          </ExplorePanel>
        </Col>
        <Col span={16}>
          <ExplorePanel className="explore-map-container">
            <RegionCountsMap showBySuppliers={showBySuppliers} />
          </ExplorePanel>
        </Col>
      </Row>
    </ExploreSection>
  );
}
