import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

export default function ExploreSection({
  children,
  className,
  description,
  subTitle,
  title
}) {
  return (
    <div className={`explore-section ${className || ''}`}>
      <Typography.Title className="mb-sm" level={4}>
        {title}
        {!!subTitle && (
          <>
            <br />
            <Typography.Text className="section-subheading">
              {subTitle}
            </Typography.Text>
          </>
        )}
      </Typography.Title>
      <Typography.Paragraph>{description}</Typography.Paragraph>
      {children}
    </div>
  );
}

ExploreSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node
};
