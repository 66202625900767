import { Col, Row, Space, Tooltip, Typography } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import {
  Box,
  BoxColumn,
  BLEND,
  PCR,
  useSummaryData
} from 'src/components/project/explore/summary/helpers';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';

const isHeadCompany = (companyType) => ['head'].includes(companyType);
const isTorsoCompany = (companyType) => ['torso'].includes(companyType);
const isTailCompany = (companyType) => ['tail'].includes(companyType);

const recordsCountFromData = (data) => data?.record_count ?? 0;
const suppliersCountFromData = (data) => data?.supplier_count ?? 0;

const recordsCountString = (recordsCount, suppliersCount) =>
  `${recordsCount} record${
    recordsCount === 1 ? '' : 's'
  } from ${suppliersCount} supplier${suppliersCount === 1 ? '' : 's'}`;

export default function SupplySummary({ compact }) {
  const { data, isLoading } = useSummaryData();

  const loadingClass = useLoadingStateClass(isLoading);

  const blendHeadData = data?.supplier_breakdown?.find(
    ({ id, company_type }) => id === BLEND && isHeadCompany(company_type)
  );
  const pcrHeadData = data?.supplier_breakdown?.find(
    ({ id, company_type }) => id === PCR && isHeadCompany(company_type)
  );

  const blendTorsoData = data?.supplier_breakdown?.find(
    ({ id, company_type }) => id === BLEND && isTorsoCompany(company_type)
  );
  const pcrTorsoData = data?.supplier_breakdown?.find(
    ({ id, company_type }) => id === PCR && isTorsoCompany(company_type)
  );

  const blendTailData = data?.supplier_breakdown?.find(
    ({ id, company_type }) => id === BLEND && isTailCompany(company_type)
  );
  const pcrTailData = data?.supplier_breakdown?.find(
    ({ id, company_type }) => id === PCR && isTailCompany(company_type)
  );

  const globalBlendSuppliers = suppliersCountFromData(blendHeadData);
  const globalPcrSuppliers = suppliersCountFromData(pcrHeadData);
  const regionalBlendSuppliers =
    suppliersCountFromData(blendTorsoData) +
    suppliersCountFromData(blendTailData);
  const regionalPcrSuppliers =
    suppliersCountFromData(pcrTorsoData) + suppliersCountFromData(pcrTailData);

  const globalBlendRecords = recordsCountFromData(blendHeadData);
  const globalPcrRecords = recordsCountFromData(pcrHeadData);
  const regionalBlendRecords =
    recordsCountFromData(blendTorsoData) + recordsCountFromData(blendTailData);
  const regionalPcrRecords =
    recordsCountFromData(pcrTorsoData) + recordsCountFromData(pcrTailData);

  const totalBlendRecords = globalBlendRecords + regionalBlendRecords;
  const totalPcrRecords = globalPcrRecords + regionalPcrRecords;

  return (
    <ExplorePanel className={loadingClass} pad>
      <Space direction="vertical" size={18}>
        <Row gutter={[16, 0]}>
          <Col style={{ marginRight: 'auto' }}>
            <Typography.Text strong>
              Supply{!compact ? ' Summary' : ''}
            </Typography.Text>
          </Col>
          <BoxColumn>
            <Typography.Text strong>Blend (+30%)</Typography.Text>
          </BoxColumn>
          <BoxColumn>
            <Typography.Text strong>100% PCR</Typography.Text>
          </BoxColumn>
        </Row>
        <Row
          className="explore-summary--kpi-row"
          gutter={[16, 0]}
          align="middle"
        >
          <Col style={{ marginRight: 'auto' }}>
            <Typography.Text strong>Global Supply</Typography.Text>
          </Col>
          <BoxColumn>
            <Tooltip
              title={recordsCountString(
                globalBlendRecords,
                globalBlendSuppliers
              )}
            >
              <Box>
                <Typography.Text strong>
                  {totalBlendRecords
                    ? Math.round((globalBlendRecords / totalBlendRecords) * 100)
                    : '--'}
                  %
                </Typography.Text>
              </Box>
            </Tooltip>
          </BoxColumn>
          <BoxColumn>
            <Tooltip
              title={recordsCountString(globalPcrRecords, globalPcrSuppliers)}
            >
              <Box>
                <Typography.Text strong>
                  {totalPcrRecords
                    ? Math.round((globalPcrRecords / totalPcrRecords) * 100)
                    : '--'}
                  %
                </Typography.Text>
              </Box>
            </Tooltip>
          </BoxColumn>
        </Row>
        <Row
          className="explore-summary--kpi-row"
          gutter={[16, 0]}
          align="middle"
        >
          <Col style={{ marginRight: 'auto' }}>
            <Typography.Text strong>Regional Supply</Typography.Text>
          </Col>
          <BoxColumn>
            <Tooltip
              title={recordsCountString(
                regionalBlendRecords,
                regionalBlendSuppliers
              )}
            >
              <Box>
                <Typography.Text strong>
                  {totalBlendRecords
                    ? Math.round(
                        (regionalBlendRecords / totalBlendRecords) * 100
                      )
                    : '--'}
                  %
                </Typography.Text>
              </Box>
            </Tooltip>
          </BoxColumn>
          <BoxColumn>
            <Tooltip
              title={recordsCountString(
                regionalPcrRecords,
                regionalPcrSuppliers
              )}
            >
              <Box>
                <Typography.Text strong>
                  {totalPcrRecords
                    ? Math.round((regionalPcrRecords / totalPcrRecords) * 100)
                    : '--'}
                  %
                </Typography.Text>
              </Box>
            </Tooltip>
          </BoxColumn>
        </Row>
      </Space>
    </ExplorePanel>
  );
}
SupplySummary.propTypes = {
  compact: PropTypes.bool
};
