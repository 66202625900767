import React from 'react';
import PropTypes from 'prop-types';

export default function ExplorePanel({
  dark,
  className,
  children,
  pad,
  style
}) {
  return (
    <div
      className={`box-panel ${dark ? 'dark' : ''} ${className || ''}`}
      style={{
        ...style,
        padding: pad ? (typeof pad === 'number' ? `${pad}px` : '24px') : '0'
      }}
    >
      {children}
    </div>
  );
}

ExplorePanel.propTypes = {
  dark: PropTypes.bool,
  className: PropTypes.string,
  pad: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  children: PropTypes.node,
  style: PropTypes.object
};
