import React from 'react';
import { ReactComponent as Icon } from './svg/marker-large-icon.svg';
import SvgIconComponent from './SvgIconComponent';

function MarkerLargeIcon(props) {
  return (
    <SvgIconComponent {...props}>
      <Icon style={{ height: 50, width: 50 }} />
    </SvgIconComponent>
  );
}

export default MarkerLargeIcon;
