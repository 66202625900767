import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';

import Sidebar from 'src/components/layout/Sidebar';
import ProjectDashboard from 'src/pages/project/ProjectDashboard';
import ProjectDetails from 'src/pages/project/details';
import InsightsPage from 'src/pages/insights';
import { DealTestPage } from 'src/pages/testing/TestLab';
import TestResults from 'src/pages/project/details/TestResults';

import 'src/pages/project/Project.less';
import ProjectExplore from 'src/pages/project/ProjectExplore';
import ProjectSearch from 'src/pages/project/ProjectSearch';
import ProjectProcure from 'src/pages/project/details/ProjectProcure';
import ProjectManage from 'src/pages/project/details/ProjectManage';
import { useUser } from 'src/utils/authentication';
import { isConcierge, isSupplier } from 'src/utils/permissions';
import Portfolio from 'src/components/portfolio';
import ProposalModal from 'src/components/project/search/ProposalModal';

function AppLayoutSidebar() {
  const { data: user } = useUser();
  const navigate = useNavigate();
  const supplier = isSupplier(user) && !isConcierge(user);

  useEffect(() => {
    if (supplier) navigate(`/supplier/${user.company.uuid}`);
  }, [user]);

  if (!user || supplier) return <Skeleton />;

  return (
    <div className="project-page">
      <Sidebar />
      <div className="project-page-contents">
        <Routes>
          <Route path="/dashboard" element={<ProjectDashboard />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/explore" element={<ProjectExplore />} />
          <Route path="/search" element={<ProjectSearch />} />
          <Route path="/proposal" element={<ProposalModal />} />
          <Route path="/procure" element={<ProjectProcure />} />
          <Route path="/procure/:rfqId/:tab?" element={<ProjectProcure />} />
          <Route path="/manage" element={<ProjectManage />} />
          <Route path="/manage/:rfqId/:tab?" element={<ProjectManage />} />
          <Route path="/:rfqId/testing/:dealId" element={<DealTestPage />} />
          <Route
            path="procure/:rfqId/testing/:dealId/results/:sampleResultId"
            element={<TestResults />}
          />
          <Route path="/insights/:tab?" element={<InsightsPage />} />
          <Route path="/:rfqId/:tab?" element={<ProjectDetails />} />
        </Routes>
      </div>
    </div>
  );
}

export default AppLayoutSidebar;
