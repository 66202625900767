import { Col, Row, Typography } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import {
  KpiName,
  useKpiUnit
} from 'src/components/project/explore/filters/KpiFilter';
import numericRange from 'src/components/utils/numericRange';
import {
  ViabilityIconBox,
  Box,
  BoxColumn,
  BLEND,
  PCR,
  useSummaryData,
  HistogramColumn
} from 'src/components/project/explore/summary/helpers';
import MinimalHistogram from 'src/components/project/explore/charts/MinimalHistogram';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';

export default function KpiRow({ kpiCode, compact }) {
  const [kpiFilters] = useConciergeContextState(['explore', 'filters', 'kpis']);

  const { data, isLoading } = useSummaryData();
  const loadingClass = useLoadingStateClass(isLoading);

  const kpiData = data?.kpis?.[kpiCode];

  const defaultUnits = useKpiUnit(kpiCode);
  const units = kpiData?.units || kpiFilters[kpiCode]?.units || defaultUnits;

  const blendCount =
    data?.kpi_breakdown?.[kpiCode]?.find(({ id }) => id === BLEND)?.tds_count ??
    0;
  const pcrCount =
    data?.kpi_breakdown?.[kpiCode]?.find(({ id }) => id === PCR)?.tds_count ??
    0;

  return (
    <Row
      className={`explore-summary--kpi-row ${loadingClass}`}
      gutter={[compact ? 8 : 16, 0]}
      wrap={false}
    >
      <Col style={{ marginRight: 'auto' }}>
        <Typography.Text strong>
          {compact ? kpiCode : <KpiName codeOrUuid={kpiCode} />}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          {numericRange({
            ...kpiFilters[kpiCode],
            empty: '(any value)',
            rangeRender: (min, max) => `${min} - ${max} ${units}`,
            noMinRender: (v) => `${v} or - ${units}`,
            noMaxRender: (v) => `${v} or + ${units}`,
            singleValueRender: (v) => `${v} ${units}`
          })}
        </Typography.Text>
      </Col>
      {!compact && kpiData && (
        <HistogramColumn>
          <MinimalHistogram specData={kpiData} kpiCode={kpiCode} />
        </HistogramColumn>
      )}
      {!compact && (
        <BoxColumn width={110}>
          <Box>
            <Typography.Text strong>
              {blendCount + pcrCount || '-'}{' '}
            </Typography.Text>
            <Typography.Text type="secondary">
              / {kpiData?.values?.length ?? '-'}
            </Typography.Text>
          </Box>
        </BoxColumn>
      )}
      <BoxColumn>
        <ViabilityIconBox recordsCount={blendCount} />
      </BoxColumn>
      <BoxColumn>
        <ViabilityIconBox recordsCount={pcrCount} />
      </BoxColumn>
    </Row>
  );
}
KpiRow.propTypes = {
  kpiCode: PropTypes.string.isRequired,
  compact: PropTypes.bool
};
