import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';

export default function Portfolio() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 4000);
  }, []);

  return (
    <>
      {loading && (
        <Skeleton
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100vh',
            background: 'white'
          }}
        />
      )}
      <iframe
        title="Portfolio Prototype"
        style={{
          width: '100%',
          height: '100vh'
        }}
        src={`https://www.figma.com/embed?${new URLSearchParams({
          embed_host: 'share',
          'hide-ui': 1,
          'show-proto-sidebar': 0,
          'hotspot-hints': 0,
          scaling: 'scale-down-width',
          url: 'https://www.figma.com/proto/fNUwQe6SaREE20QqRbxd0g/Portfolio?page-id=1%3A13&node-id=2299-15162&viewport=1561%2C1498%2C0.83&t=gqdOEvXnzT78onvg-1&scaling=contain&starting-point-node-id=2299%3A15162&content-scaling=fixed'
        }).toString()}`}
      />
    </>
  );
}
