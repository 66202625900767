import { Col, Divider, Row, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';

import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import BookmarkIcon from 'src/components/icons/Bookmark';
import {
  BoxColumn,
  HistogramColumn
} from 'src/components/project/explore/summary/helpers';
import KpiRow from 'src/components/project/explore/summary/KpiRow';
import { CaretRightFilled } from '@ant-design/icons';
import SupplySummary from 'src/components/project/explore/summary/SupplySummary';
import PriceSummary from './PriceSummary';

export default function FullExploreSummary() {
  const [showSecondaryKpis, setShowSecondaryKpis] = useState();

  const [kpiFilters] = useConciergeContextState(['explore', 'filters', 'kpis']);
  const primaryKpis = [];
  const secondaryKpis = [];
  Object.keys(kpiFilters).forEach((code) => {
    if (!kpiFilters[code].allow_null) {
      primaryKpis.push(code);
    } else {
      secondaryKpis.push(code);
    }
  });

  return (
    <div className="explore-summary--page">
      <Typography.Title level={4}>Explore Results Summary</Typography.Title>
      <Typography.Paragraph>
        Filtered specification data is compared against our database of supplier
        records. View a summary of the comparison and where else you could
        source material at the desired specification. Investigate further by
        viewing detailed curves of our dataset and supplier ranges.
      </Typography.Paragraph>
      <ExplorePanel className="explore-section explore-summary" pad>
        <Typography.Paragraph strong style={{ fontSize: 16 }}>
          <BookmarkIcon /> Primary Specifications Summary
        </Typography.Paragraph>
        <Row gutter={[10, 0]}>
          <Col span="24">
            <Space direction="vertical" size={18}>
              <Row gutter={[16, 0]} justify="end">
                <HistogramColumn>
                  <Typography.Text style={{ marginLeft: 8 }} strong>
                    Values
                  </Typography.Text>
                </HistogramColumn>
                <BoxColumn width={100}>
                  <Typography.Text strong>Rec.</Typography.Text>
                  <Typography.Text type="secondary">/ Total</Typography.Text>
                </BoxColumn>
                <BoxColumn>
                  <Typography.Text strong>Blend (+30%)</Typography.Text>
                </BoxColumn>
                <BoxColumn>
                  <Typography.Text strong>100% PCR</Typography.Text>
                </BoxColumn>
              </Row>
              {primaryKpis.map((code) => (
                <KpiRow showHistogram kpiCode={code} key={code} />
              ))}
            </Space>
          </Col>
        </Row>

        {secondaryKpis?.length ? (
          <>
            <Divider />

            <Typography.Paragraph
              onClick={() => setShowSecondaryKpis(!showSecondaryKpis)}
              strong
              style={{ fontSize: 16, cursor: 'pointer' }}
            >
              <CaretRightFilled rotate={showSecondaryKpis ? 90 : 0} /> Secondary
              Specifications Summary
            </Typography.Paragraph>
            {showSecondaryKpis ? (
              <Row gutter={[10, 0]}>
                <Col span="24">
                  <Space direction="vertical" size={18}>
                    {secondaryKpis.map((code) => (
                      <KpiRow showHistogram kpiCode={code} key={code} />
                    ))}
                  </Space>
                </Col>
              </Row>
            ) : null}
          </>
        ) : null}
      </ExplorePanel>
      <Row gutter={[16, 0]}>
        <Col span="12">
          <SupplySummary />
        </Col>
        <Col span="12">
          <PriceSummary />
        </Col>
      </Row>
    </div>
  );
}
