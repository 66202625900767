import PropTypes from 'prop-types';
import { Button, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import numericRange from 'src/components/utils/numericRange';
import { currencyValuePrice } from 'src/utils/currency';
import { KpiName } from 'src/components/project/explore/filters/KpiFilter';
import TdsQuantity from 'src/components/project/search/TdsQuantity';
import SupplierAvatar from 'src/components/project/search/SupplierAvatar';

export default function CompareModal({ selectedRows = [], btnText, btnProps }) {
  const [open, setOpen] = useState();
  const [kpis] = useConciergeContextState(['explore', 'filters', 'kpis']);

  return (
    <>
      <Button
        disabled={!selectedRows.length}
        onClick={() => setOpen(true)}
        size="small"
        {...(btnProps || {})}
      >
        {btnText || 'Compare'}
      </Button>
      <Modal
        width={800}
        title="Compare Suppliers"
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <div
          className="supplier-compare"
          style={{
            gridTemplateColumns: `repeat(${1 + selectedRows.length},1fr)`
          }}
        >
          <div className="supplier-compare--row">
            <div />
            {selectedRows.map((tds) => (
              <SupplierAvatar key={tds.id} tds={tds} />
            ))}
          </div>
          <div className="supplier-compare--row">
            <div />
            {selectedRows.map((tds) => (
              <Typography.Title key={tds.id} level={5}>
                {tds.company_name}
              </Typography.Title>
            ))}
          </div>
          <div className="supplier-compare--row">
            <Typography.Text>Material</Typography.Text>
            {selectedRows.map((tds) => (
              <div key={tds.id}>{tds.type}</div>
            ))}
          </div>
          <div className="supplier-compare--row">
            <Typography.Text>Form</Typography.Text>
            {selectedRows.map((tds) => (
              <div key={tds.id}>{tds.form}</div>
            ))}
          </div>
          <div className="supplier-compare--row">
            <Typography.Text>Quantity</Typography.Text>
            {selectedRows.map((tds) => (
              <div key={tds.id}>
                <TdsQuantity tds={tds} />
              </div>
            ))}
          </div>
          <div className="supplier-compare--row">
            <Typography.Text>Material Cost /lb</Typography.Text>
            {selectedRows.map((tds) => (
              <div key={tds.id}>{currencyValuePrice(tds.price_per_lb)}</div>
            ))}
          </div>
          {/* KPIs */}
          {kpis &&
            Object.keys(kpis).map((kpiCode) => (
              <div className="supplier-compare--row" key={kpiCode}>
                <div>
                  <KpiName codeOrUuid={kpiCode} />
                </div>
                {selectedRows.map((tds) => {
                  const kpiKey = `kpi_${kpiCode}`;
                  const tdsKpiMin = tds[`${kpiKey}.min`];
                  const tdsKpiMax = tds[`${kpiKey}.max`];
                  const tdsKpiUnits = tds[`${kpiKey}.units`];
                  return (
                    <div key={tds.id}>
                      {numericRange({
                        min: tdsKpiMin,
                        max: tdsKpiMax,
                        empty: '–',
                        rangeRender: (min, max) =>
                          `${min} - ${max} ${tdsKpiUnits}`,
                        noMinRender: (v) => `${v} or - ${tdsKpiUnits}`,
                        noMaxRender: (v) => `${v} or + ${tdsKpiUnits}`,
                        singleValueRender: (v) => `${v} ${tdsKpiUnits}`
                      })}
                    </div>
                  );
                })}
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
}
CompareModal.propTypes = {
  selectedRows: PropTypes.array,
  btnText: PropTypes.string,
  btnProps: PropTypes.object
};
