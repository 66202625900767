/**
 * This bucketing algorithm is for initial use in creating a histogram where
 * we want to create a number of buckets that cover the range of values (min to max)
 * and have these buckets be "nice round" values.
 *
 * For example, we allow bucket sizes like 1, 2, 5, 10, 20, 50, 100.... and 0.5, 0.2, 0.1, 0.05, 0.02, 0.01....
 *
 * The algorithm chooses the closest of these bucket sizes to achieve a target number of buckets
 */

// we treat these as valid step sizes that will be easy for a user to understand
// and also all scaling of these by 10^n where n is an integer
const allowedStepSizes = [1, 2, 5, 1];

// these are the  thresholds that will round up to the above allowed values
// for example, we will round to the first bucket of 1 if the value is between the first two numbers in this array
// there must be one more entry in the array compared to allowedStepSizes to give the threshold at which we wrap up
// to the next order of magnitude. which means that the last entry must be 10 times bigger than the first
const allowedStepSizeThresholds = [0.75, 1.5, 3.5, 7.5, 15];

// we work off logs, so we convert these to logs for easy comparison
const allowedStepSizeThresholdsLog10 = allowedStepSizeThresholds.map((n) =>
  Math.log10(n)
);

export const bucketStepSize = (min, max, steps) => {
  const range = max - min;
  const exactStepSize = range / steps;
  const logExactStepSize = Math.log10(exactStepSize);
  // get the decimal part of the log to fit in the range from allowedStepSizeThresholds[0] to allowedStepSizeThresholds[n-1]
  const normalizedLogStepSize =
    ((logExactStepSize - allowedStepSizeThresholdsLog10[0]) % 1) +
    allowedStepSizeThresholdsLog10[0];
  const reflectedLogStepSize =
    normalizedLogStepSize > allowedStepSizeThresholdsLog10[0]
      ? normalizedLogStepSize
      : 1 + normalizedLogStepSize;
  const digits = Math.floor(
    logExactStepSize - allowedStepSizeThresholdsLog10[0]
  );
  const preferredStepSizeLogIndex =
    allowedStepSizeThresholdsLog10.findIndex((n) => n > reflectedLogStepSize) -
    1;
  const adjustedDigits =
    preferredStepSizeLogIndex === allowedStepSizeThresholdsLog10.length - 1
      ? digits + 1 // we have wrapped into the next order of magnitude
      : digits;
  const preferredStepSize = allowedStepSizes[preferredStepSizeLogIndex];
  // add back the digits we removed when we normalized to the range 0 to 10
  return preferredStepSize * 10 ** adjustedDigits;
};

export const normalizeMinMaxComponents = (v, fn, baseLocked) => {
  const log = Math.log10(v);
  const exp = Math.floor(log);
  const base = baseLocked || 10 ** exp;
  return [fn(v / base) * base, base];
};

export const normalizeMinMax = (min, max) => {
  let [normalizedMax, baseMax] = normalizeMinMaxComponents(max, Math.ceil);
  if ((normalizedMax - max) / (max - min) > 0.25) {
    [normalizedMax, baseMax] = normalizeMinMaxComponents(
      max,
      Math.ceil,
      baseMax / 10
    );
  }

  let [normalizedMin, baseMin] = normalizeMinMaxComponents(
    min,
    Math.floor,
    baseMax
  );
  if ((min - normalizedMin) / (max - min) > 0.25) {
    [normalizedMin, baseMin] = normalizeMinMaxComponents(
      min,
      Math.floor,
      baseMin / 10
    );
  }

  return [normalizedMin, normalizedMax];
};
