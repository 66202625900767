import { continentMap } from 'src/utils/regions';
import { NO_DATA } from 'src/components/GoodnessRating';
import useLocationConfidenceRating from 'src/hooks/useLocationConfidenceRating';
import useSpecConfidenceRating from 'src/hooks/useSpecConfidenceRating';
import usePricingConfidenceRating from 'src/hooks/usePricingConfidenceRating';
import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getRegionalAnalytics } from 'src/Query/warehouse';
import { KpiFilterSelectionSummary } from 'src/components/project/explore/KpiFilterSelectionSummary';
import useOverallConfidenceRating from 'src/hooks/useOverallConfidenceRating';
import { reduceRegionalAnalytics } from 'src/utils/aggregations';

const useConfidenceSummary = ({ filters }) => {
  const { kpis: specsFilter = {}, ...filtersWithoutSpecs } = filters || {};

  const { region_code__in: regionsFilter = [] } = filters || {};

  const { data: totalRecords } = useQuery({
    queryKey: ['warehouse', 'regional-analytics', filtersWithoutSpecs],
    queryFn: () => getRegionalAnalytics(filtersWithoutSpecs),
    keepPreviousData: true
  });

  const regionCountsAggregate = useMemo(
    () => (totalRecords || []).reduce(reduceRegionalAnalytics, {}),
    [totalRecords]
  );

  const calculatedLocationRating = useLocationConfidenceRating({ filters });
  const calculatedSpecRating = useSpecConfidenceRating({ filters });
  const calculatedPricingRating = usePricingConfidenceRating({ filters });

  const { overallRating, overallRatingPercentage } = useOverallConfidenceRating(
    {
      filters,
      locationRating: calculatedLocationRating,
      pricingRating: calculatedPricingRating,
      specRating: calculatedSpecRating
    }
  );

  const locationRating =
    regionsFilter?.length > 0 ? calculatedLocationRating : NO_DATA;

  const specRating =
    Object.keys(specsFilter).length > 0 ? calculatedSpecRating : NO_DATA;

  const locationText =
    regionsFilter?.length > 0
      ? `${regionCountsAggregate.tdss || 0} Records from ${
          regionCountsAggregate.suppliers || 0
        } Suppliers in ${regionsFilter
          .map((regionKey) => continentMap[regionKey].name)
          .join(', ')}`
      : 'Is the material found in the regions you are looking in?';

  const pricingText = ''; // TODO: add some text here

  const specContent = Object.keys(specsFilter).map((kpiCode) => (
    <KpiFilterSelectionSummary
      key={kpiCode}
      kpiCode={kpiCode}
      filter={specsFilter[kpiCode]}
    />
  ));

  return {
    locationRating,
    locationText,
    overallRating,
    overallRatingPercentage,
    pricingRating: calculatedPricingRating,
    pricingText,
    specContent,
    specRating
  };
};

export default useConfidenceSummary;
