import React, { useMemo } from 'react';
/* eslint-disable import/no-unresolved */
import { Doughnut } from 'react-chartjs-2';

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import PropTypes from 'prop-types';
import { getRegionalAnalytics } from 'src/Query/warehouse';
import { continentMap } from 'src/utils/regions';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
/* eslint-disable import/no-unresolved */

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  cutout: '84%',
  plugins: {
    legend: {
      align: 'start',
      maxWidth: 120,
      pointStyle: 'circle',
      position: 'bottom',
      reverse: true,
      labels: {
        color: '#323640',
        boxHeight: 6,
        boxWidth: 6,
        font: '10',
        padding: 15,
        pointStyle: 'circle',
        usePointStyle: true
      }
    }
  }
};

function RegionCountsDoughnutChart({ showBySuppliers }) {
  const filters = useRelevantFiltersForForm();

  const { region_code__in: regionsFilter = [], ...filtersWithoutRegion } =
    filters;

  const {
    data: filteredRecords,
    isLoading: isLoadingFilteredRecords,
    isPreviousData: isPreviousDataFilteredRecords
  } = useDebouncedQuery({
    queryKey: ['warehouse', 'regional-analytics', filters],
    queryFn: () => getRegionalAnalytics(filters),
    keepPreviousData: true
  });

  const {
    data: totalRecords,
    isLoading: isLoadingTotalRecords,
    isPreviousData: isPreviousDataTotalRecords
  } = useDebouncedQuery({
    queryKey: ['warehouse', 'regional-analytics', filtersWithoutRegion],
    queryFn: () => getRegionalAnalytics(filtersWithoutRegion),
    keepPreviousData: true
  });

  const loadingStateClass = useLoadingStateClass(
    isLoadingFilteredRecords ||
      isLoadingTotalRecords ||
      isPreviousDataFilteredRecords ||
      isPreviousDataTotalRecords
  );

  const isFilteringRegions = regionsFilter.length > 0;

  const countKey = showBySuppliers ? 'suppliers' : 'tdss';

  const totalCountAggregate = useMemo(
    () => (totalRecords || []).reduce((prev, curr) => prev + curr[countKey], 0),
    [countKey, totalRecords]
  );

  const filteredCountAggregate = useMemo(
    () =>
      (filteredRecords || [])
        .filter((record) => regionsFilter.includes(record.region_code))
        .reduce((prev, curr) => prev + curr[countKey], 0),
    [countKey, filteredRecords]
  );

  const filteredRegions = isFilteringRegions
    ? [
        {
          name: regionsFilter
            .map((regionKey) => continentMap[regionKey].name)
            .join(', '),
          count: filteredCountAggregate
        }
      ]
    : [];

  const filteredCount = filteredRegions[0]?.count ?? 0;

  const otherRegions = [
    {
      name: isFilteringRegions ? 'Other regions' : 'All regions',
      count: totalCountAggregate - filteredCount
    }
  ];

  const totalCount = otherRegions[0]?.count ?? 0;

  const backgroundColors = [];
  const labels = [];
  const counts = [];

  const regionColor = isFilteringRegions ? '#2F54EB' : '#DCE2EB';
  const otherColor = !isFilteringRegions ? '#2F54EB' : '#DCE2EB';

  otherRegions.forEach((r) => {
    backgroundColors.push(otherColor);
    labels.push(r.name);
    counts.push(r.count);
  });

  filteredRegions.forEach((r) => {
    backgroundColors.push(regionColor);
    labels.push(r.name);
    counts.push(r.count);
  });

  const data = {
    labels,
    datasets: [
      {
        label: 'Records',
        data: counts,
        backgroundColor: backgroundColors,
        borderWidth: 0
      }
    ]
  };

  const spacing = otherRegions.length > 0 && filteredRegions.length > 0 ? 2 : 0;

  const totalLabel = useMemo(
    () => ({
      id: 'totalLabel',
      afterDatasetsDraw: function renderTotalLabel(chart) {
        const { ctx } = chart;

        const {
          centerTextHeading: optCenterTextHeading,
          centerTextUnit: optCenterTextUnit,
          totalCount: optTotalCount
        } = chart.config.options;

        ctx.save();

        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;

        ctx.font = '400 12px Roboto';
        ctx.fillStyle = '#5D697B';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        const headingHeight = 6;
        const recordsText = `${optTotalCount} ${optCenterTextUnit}`;
        const recordsTextHeight = 8;

        const gapDividedTwo = 4; // for a gap of 8

        ctx.fillText(
          optCenterTextHeading,
          centerX,
          centerY - headingHeight - gapDividedTwo
        );

        ctx.restore();

        ctx.save();

        ctx.font = '500 16px Roboto';
        ctx.fillStyle = '#323640';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        ctx.fillText(
          recordsText,
          centerX,
          centerY + recordsTextHeight + gapDividedTwo
        );

        ctx.restore();
      }
    }),
    []
  );

  const centerTextHeading = showBySuppliers
    ? 'Total Suppliers in Region'
    : 'Total Records in Region';

  const centerTextUnit = showBySuppliers ? 'Suppliers' : 'Records';

  const computedOptions = useMemo(
    () => ({
      ...options,
      centerTextHeading,
      centerTextUnit,
      spacing,
      totalCount: isFilteringRegions ? filteredCount : totalCount
    }),
    [
      centerTextHeading,
      centerTextUnit,
      filteredCount,
      isFilteringRegions,
      options,
      spacing,
      totalCount
    ]
  );

  return (
    <Doughnut
      className={loadingStateClass}
      data={data}
      options={computedOptions}
      plugins={[totalLabel]}
    />
  );
}

RegionCountsDoughnutChart.propTypes = {
  showBySuppliers: PropTypes.bool
};

export default RegionCountsDoughnutChart;
