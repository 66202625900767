import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useDebounce } from 'src/hooks/useDebounce';

export const DEFAULT_DEBOUNCE_TIME = 2000;

export default function useDebouncedQuery(...props) {
  const firstArg = props[0];
  const propsAsObject = !Array.isArray(firstArg) && !!firstArg.queryKey;

  const queryKey = propsAsObject ? firstArg.queryKey : firstArg;
  const debounce = propsAsObject
    ? firstArg.debounce
    : props[2]?.debounce || DEFAULT_DEBOUNCE_TIME;

  const debouncedKey = useDebounce(queryKey, debounce);

  const updatedProps = useMemo(() => {
    if (propsAsObject)
      return [
        {
          queryKey,
          ...firstArg
        }
      ];

    return [queryKey, ...props.slice(1)];
  }, [debouncedKey, propsAsObject]);

  return useQuery(...updatedProps);
}
