import React, { useEffect, useMemo, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { getRegions } from 'src/Query';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { MapContext } from 'src/pages/maps/MapContext';
import useMap from 'src/pages/maps/hooks/useMap';
import { Marker } from 'src/pages/maps/Marker';
import { getRegionalAnalytics } from 'src/Query/warehouse';
import { continentMap } from 'src/utils/regions';
import { Tooltip } from 'antd';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import { reduceRegionalAnalyticsByRegion } from 'src/utils/aggregations';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';

const FILTER_OUT_RUSSIA = (c) => c.code !== 'RU';

export default function RegionCountsMap({ showBySuppliers }) {
  const filters = useRelevantFiltersForForm();

  const { region_code__in: regionsFilter = [], ...filtersWithoutRegion } =
    filters;

  const isFilteringRegion = regionsFilter.length > 0;

  const { map, mapContainer } = useMap({
    mapProps: { interactive: false, zoom: 1 },
    disableControls: true
  });

  const {
    data: totalRecords,
    isLoading: isLoadingRecords,
    isPreviousData: isPreviousDataRecords
  } = useDebouncedQuery({
    queryKey: ['warehouse', 'regional-analytics', filtersWithoutRegion],
    queryFn: () => getRegionalAnalytics(filtersWithoutRegion),
    keepPreviousData: true
  });

  const {
    data: allRegionsData,
    isLoading: isLoadingRegions,
    isPreviousData: isPreviousDataRegions
  } = useQuery({
    queryKey: ['regions'],
    queryFn: () => getRegions(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24 * 7,
    cacheTime: 1000 * 60 * 60 * 24 * 7
  });

  const loadingStateClass = useLoadingStateClass(
    isLoadingRecords ||
      isLoadingRegions ||
      isPreviousDataRecords ||
      isPreviousDataRegions
  );

  const highlightCountryCodes = useMemo(
    () =>
      allRegionsData
        ?.filter((r) => regionsFilter.includes(r.code))
        ?.flatMap((r) =>
          r.countries.filter(FILTER_OUT_RUSSIA).map((c) => c.code)
        ) || [],
    [allRegionsData, regionsFilter]
  );

  const [mapStylesLoaded, setMapStylesLoaded] = useState(false);

  // Center the globe to the Atlantic Ocean
  useEffect(() => {
    if (map)
      setTimeout(() => {
        map.resize();

        const bounds = new mapboxgl.LngLatBounds();

        Object.values(continentMap).forEach(({ latitude, longitude }) =>
          bounds.extend([longitude, latitude])
        );

        map.fitBounds(bounds, { padding: 100 });
      }, 250);
  }, [map]);

  const countryLayerId = 'country-boundaries';
  const mapCountryLayer = map?.getLayer(countryLayerId);

  useEffect(() => {
    if (!map || map.getLayer(countryLayerId)) return;

    map?.on('styledata', () => {
      setMapStylesLoaded(true);
    });

    map.on('load', () => {
      map.addLayer(
        {
          id: countryLayerId,
          source: {
            type: 'vector',
            url: 'mapbox://mapbox.country-boundaries-v1'
          },
          'source-layer': 'country_boundaries',
          type: 'fill',
          paint: {
            'fill-color': '#2F54EB',
            'fill-opacity': 1
          },
          filter: isFilteringRegion
            ? ['in', 'iso_3166_1', ...(highlightCountryCodes || [])]
            : ['all']
        },
        'country-label'
      );
      map.resize();

      const bounds = new mapboxgl.LngLatBounds();

      Object.values(continentMap).forEach(({ latitude, longitude }) =>
        bounds.extend([longitude, latitude])
      );

      map.fitBounds(bounds, { padding: 100 });
    });
  }, [map]);

  useEffect(() => {
    if (!mapStylesLoaded || !mapCountryLayer) return;
    map.setFilter(
      countryLayerId,
      isFilteringRegion ? ['in', 'iso_3166_1', ...highlightCountryCodes] : null
    );
  }, [highlightCountryCodes, map, mapCountryLayer, mapStylesLoaded]);

  const countKey = showBySuppliers ? 'suppliers' : 'tdss';

  const regionCountsAggregate = useMemo(
    () => (totalRecords || []).reduce(reduceRegionalAnalyticsByRegion, {}),
    [totalRecords]
  );

  const markers = useMemo(
    /* eslint-disable react/no-array-index-key */
    () => [
      ...(map
        ? Object.keys(regionCountsAggregate).map(
            (regionCode, index) =>
              regionCountsAggregate[regionCode] &&
              continentMap[regionCode] && (
                <TDSCountMarker
                  supplier={{
                    ...regionCountsAggregate[regionCode],
                    lat: continentMap[regionCode].latitude,
                    long: continentMap[regionCode].longitude
                  }}
                  id={`${regionCode}-${index}`}
                  key={`${regionCode}-${index}`}
                  map={map}
                  size={10}
                  value={regionCountsAggregate[regionCode][countKey]}
                />
              )
          )
        : [])
    ],
    [countKey, map, regionCountsAggregate]
    /* eslint-enable react/no-array-index-key */
  );

  return (
    <MapContext.Provider value={map}>
      <div ref={mapContainer} className={`locations-map ${loadingStateClass}`}>
        {markers}
      </div>
    </MapContext.Provider>
  );
}

const formatter = Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });

RegionCountsMap.propTypes = {
  showBySuppliers: PropTypes.bool
};

export function TDSCountMarker({
  supplier,
  id,
  map,
  size = 10,
  style,
  value,
  handleMarkerClick
}) {
  return (
    <Marker
      key={id}
      map={map}
      id={id}
      size={size}
      latitude={Number(supplier.lat)}
      longitude={Number(supplier.long)}
    >
      <Tooltip
        title={`${supplier.tdss} records from ${supplier.suppliers} suppliers`}
        overlayInnerStyle={{
          fontSize: 12,
          minHeight: 0,
          padding: '4px 8px',
          borderRadius: 4
        }}
      >
        <span
          role="presentation"
          style={{
            backgroundColor: '#1E233B',
            borderRadius: '12px',
            color: '#FFFFFF',
            fontFamily: 'Poppins',
            fontSize: 10,
            lineHeight: '12px',
            fontWeight: 600,
            padding: '4px 10px',
            display: 'inline-block',
            textAlign: 'center',
            ...(style || {})
          }}
          className="supply-marker-content"
          onClick={() => {
            if (handleMarkerClick)
              handleMarkerClick(map, supplier.lat, supplier.long);
          }}
        >
          {formatter.format(value)}
        </span>
      </Tooltip>
    </Marker>
  );
}

TDSCountMarker.propTypes = {
  supplier: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  map: PropTypes.object,
  size: PropTypes.number.isRequired,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleMarkerClick: PropTypes.func
};
