import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useUser } from 'src/utils/authentication';

export default function ChatPopup() {
  const app_id = 'wfz5znqf';
  const loggedOutSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id
  };
  const script = useMemo(
    () => `
  window.intercomSettings = ${JSON.stringify(loggedOutSettings)};
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;
  var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;
  s.src='https://widget.intercom.io/widget/${app_id}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};
  if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  `,
    []
  );
  const { data: user } = useUser();
  useEffect(() => {
    try {
      if (user && typeof window.Intercom === 'function') {
        const loggedInSettings = {
          ...loggedOutSettings,
          name: user.name,
          email: user.email,
          user_hash: user.intercom_hmac_hash,
          created_at: user.created_at
        };
        window.Intercom('boot', loggedInSettings);
      } else if (typeof window.Intercom === 'function') {
        window.Intercom('shutdown');
      }
    } catch (e) {
      window.console.log('Error initializing intercom', e);
    }
  }, [user, window.Intercom]);
  return (
    <Helmet>
      <script>{script}</script>
    </Helmet>
  );
}
