import React from 'react';
import PropTypes from 'prop-types';

import { useKpiUnit } from 'src/components/project/explore/filters/KpiFilter';
import { MinMaxRangeString } from 'src/components/project/explore/MinMaxRangeString';

export function KpiFilterSelectionSummary({ filter, kpiCode }) {
  const defaultUnits = useKpiUnit(kpiCode);
  const kpiUnit = filter.units || defaultUnits;

  return (
    <span>
      {kpiCode}:{' '}
      <MinMaxRangeString max={filter.max} min={filter.min} kpiUnit={kpiUnit} />
    </span>
  );
}
KpiFilterSelectionSummary.propTypes = {
  kpiCode: PropTypes.string,
  filter: PropTypes.object
};
