import { Button, Radio } from 'antd';
import React from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';

export default function CapacityFilter() {
  const [capacityFilter, setCapacityFilter, clearCapacityFilter] =
    useConciergeContextState(['explore', 'filters', 'capacity__gte']);

  return (
    <>
      <Radio.Group
        options={[
          { value: '10000', label: '>10k lbs' },
          { value: '100000', label: '>100k lbs' },
          { value: '1000000', label: '>1M lbs' },
          { value: '10000000', label: '>10M lbs' },
          { value: '100000000', label: '>100M lbs' }
        ]}
        value={capacityFilter}
        onChange={({ target }) => setCapacityFilter(target.value)}
      />
      {capacityFilter && (
        <Button onClick={clearCapacityFilter} type="link" htmlType="button">
          Clear
        </Button>
      )}
    </>
  );
}
