import { UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { Avatar } from 'antd';
import React from 'react';
import { getCompany } from 'src/Query';

export default function SupplierAvatar({ tds }) {
  const { data } = useQuery(['company', tds.company_id], () =>
    getCompany(tds.company_id)
  );

  return data?.avatar ? (
    <div>
      <img
        src={data?.avatar}
        alt={tds.company_name}
        className="supplier-compare--company-avatar"
      />
    </div>
  ) : (
    <div>
      <Avatar size={35} icon={<UserOutlined />} />
    </div>
  );
}
SupplierAvatar.propTypes = {
  tds: PropTypes.object
};
