import { fetch } from 'src/utils/client';

export const getUser = (userId) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/user/${userId}/`);

export const getUsers = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/user/?${new URLSearchParams(
      params
    ).toString()}`
  );

export const getRequestsForInformation = (pagination) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/request-for-information/?page=${pagination.page}&page_size=${pagination.pageSize}`
  );

export const getRFQ = (uuid) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/request-for-quote/${uuid}/`);

export const getRFQs = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/request-for-quote/?${new URLSearchParams(params).toString()}`
  );

export const getTDSs = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/technical-data-sheet/?${new URLSearchParams(params).toString()}`
  );

export const getSuppliers = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/technical-data-sheet/suppliers/?${new URLSearchParams(params).toString()}`
  );

export const getTdsCsv = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/technical-data-sheet/?${new URLSearchParams(params).toString()}`,
    { headers: { Accept: 'text/csv' } }
  );

export const getTdsGeoAnalytics = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/technical-data-sheet/geo-analytics/?${new URLSearchParams(
      params
    ).toString()}`
  );

export const getTDS = (id) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/technical-data-sheet/${id}/`);

export const getProperties = (propertyId) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/property/${
      propertyId ? `${propertyId}/` : ''
    }?relationships=true`
  );

export const getPropertyOption = (optionId) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/property-option/${
      optionId ? `${optionId}/` : ''
    }`
  );

export const getPropertyGroups = () =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/property-group/`);

export const getNumericalProperties = (propertyId) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/numerical-property/${
      propertyId ? `${propertyId}/` : ''
    }?relationships=true`
  );

export const getNumericalPropertyTestConditions = (propertyId) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/numerical-property/${propertyId}/test-conditions/`
  );

export const getNumericalPropertyGroups = () =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/numerical-property-group/`);

export const getPopulatedNumericalPropertyGroups = () =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/numerical-property-group/populated/`
  );

export const getCompanyFunctions = () =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/company-function/`);

export const getEvent = (eventId = undefined) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/events/${eventId}/`);

export const getVerifiedCertification = (verifiedCertificationId = undefined) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/verified-certification/${verifiedCertificationId}/`
  );

export const getDeal = (dealId = undefined) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/deal/${dealId}/`);

export const getDeals = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/deal/?${new URLSearchParams(
      params
    ).toString()}`
  );

export const getDealSampleResults = (
  dealId = undefined,
  sampleResultId = undefined
) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/deal/${dealId}/sample-result/${sampleResultId}`
  );

export const getSpotPrices = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/material-spot-price/?${new URLSearchParams(params).toString()}`
  );

export const getLatestIndexPrice = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/material-spot-price/latest-index-price/?${new URLSearchParams(
      params
    ).toString()}`
  );

export const getEvents = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/event/?${new URLSearchParams(
      params
    ).toString()}`
  );

export const getPressContent = () =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/press-content/`);

export const getCompanies = (params) =>
  (params &&
    fetch(
      `${process.env.REACT_APP_CIRCLE_API}/company/?${new URLSearchParams(
        params
      ).toString()}`
    )) ||
  fetch(`${process.env.REACT_APP_CIRCLE_API}/company/`);

export const getCompany = (id) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/company/${id}/`);

export const getCompanyActivity = (id) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/company/${id}/activity/`);

export const getCompanySfAccount = (id) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/company/${id}/salesflare/account`);

export const getOnboarding = () =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/onboarding/?all=true`);

export const getRfqProjects = () =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/request-for-quote/project/`);

export const getRfqProject = (id) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/request-for-quote/${id}/project/`);

export const getDemoProject = () =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/request-for-quote/demo-project/`);

export const getProjectsForUser = () =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/request-for-quote/project-for-user/`
  );

export const getInsightsNews = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/insights-news/?${new URLSearchParams(
      params
    ).toString()}`
  );

export const getInsightsAnalysis = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/insights-analysis/?${new URLSearchParams(params).toString()}`
  );

export const getRegions = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/region/?${new URLSearchParams(
      params
    ).toString()}`
  );

export const saveExploreResults = (params) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/explore-results/store/`, {
    method: 'POST',
    body: JSON.stringify(params)
  });

export const getUnits = (params) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/units/`);

export const getCertifications = () =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/certification/?all=true`);

export const getPriceLogs = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/price-log/?${new URLSearchParams(
      params
    ).toString()}`
  );

export const getProject = (uuid) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/project/${uuid}/`);

export const getProjectWithTdss = (uuid, filters) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/project/${uuid}/populated/?${new URLSearchParams(
      filters ?? {}
    ).toString()}`
  );

export const getProjects = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/project/?${new URLSearchParams(
      params
    ).toString()}`
  );

export const getProjectWorkflows = (uuid) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/project/${uuid}/campaigns/`);

export const getTdsObjects = (data) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/technical-data-sheet/?${new URLSearchParams(data).toString()}`
  );

export const getTDSsWithCohorts = (data) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/technical-data-sheet/cohorts/?${new URLSearchParams(data).toString()}`
  );

export const getTDSCohorts = (tdsId, locationId) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/technical-data-sheet/${tdsId}/cohorts/?${new URLSearchParams({
      locationId
    }).toString()}`
  );

export const getTdsPriceInsights = (uuid) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/technical-data-sheet/${uuid}/insights/`
  );
