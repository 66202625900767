import { useQuery } from '@tanstack/react-query';
import { getRegionalAnalytics } from 'src/Query/warehouse';
import {
  EXCELLENT,
  FAIR,
  GOOD,
  POOR,
  VERY_GOOD
} from 'src/components/GoodnessRating';
import { useMemo } from 'react';
import { reduceRegionalAnalytics } from 'src/utils/aggregations';

const tdsCountRatingRules = [
  // S0 rules, including spec filters
  {
    rating: EXCELLENT,
    fn: (s0Count) => s0Count >= 10,
    priority: 1
  },
  {
    rating: VERY_GOOD,
    fn: (s0Count, s0Proportion) =>
      s0Count >= 5 && s0Count <= 9 && s0Proportion >= 0.4,
    priority: 2
  },
  {
    rating: GOOD,
    fn: (s0Count, s0Proportion) =>
      s0Count >= 5 && s0Count <= 9 && s0Proportion < 0.4,
    priority: 3
  },
  {
    rating: FAIR,
    fn: (s0Count) => s0Count >= 3 && s0Count <= 4,
    priority: 4
  },
  {
    rating: POOR,
    fn: (s0Count) => s0Count <= 2,
    priority: 5
  }
];

const sortByPriority = (rule1, rule2) => rule1.priority - rule2.priority;

const getRatingFromCount = (s0Count, s0Proportion) => {
  const rules = tdsCountRatingRules
    .filter((r) => r.fn(s0Count, s0Proportion))
    .sort(sortByPriority);

  if (rules.length === 0) throw new Error('No rules found for the TDS count');

  return rules[0].rating;
};

const useSpecConfidenceRating = ({ filters }) => {
  const { region_code__in: _regionsFilter = [], ...filtersWithoutRegion } =
    filters || {};

  // S0
  const { data: specAndRegionFilteredRecords } = useQuery({
    queryKey: ['warehouse', 'regional-analytics', filters],
    queryFn: () => getRegionalAnalytics(filters),
    keepPreviousData: true
  });

  // S0 all
  const { data: noRegionRecords } = useQuery({
    queryKey: ['warehouse', 'regional-analytics', filtersWithoutRegion],
    queryFn: () => getRegionalAnalytics(filtersWithoutRegion),
    keepPreviousData: true
  });

  // S0 count
  const specAndRegionFilteredRecordCount = useMemo(
    () =>
      (specAndRegionFilteredRecords || []).reduce(reduceRegionalAnalytics, {})
        .tdss || 0,
    [specAndRegionFilteredRecords]
  );

  // S0 all count
  const noRegionRecordCount = useMemo(
    () => (noRegionRecords || []).reduce(reduceRegionalAnalytics, {}).tdss || 0,
    [noRegionRecords]
  );

  const locationSpecificProportion =
    specAndRegionFilteredRecordCount / noRegionRecordCount;

  return getRatingFromCount(
    specAndRegionFilteredRecordCount,
    locationSpecificProportion
  );
};

export default useSpecConfidenceRating;
