import { prettyNumberPrice } from 'src/components/utils/prettyNumber';

const currencySymbolMap = {
  EUR: '€',
  USD: '$',
  USC: '¢',
  JPY: '￥'
};

export const currencySymbol = (currency) =>
  (currency && (currencySymbolMap[currency] || `${currency} `)) || '$';

export const currencyValuePrice = (value, currency, units = null) =>
  `${currencySymbol(currency)}${prettyNumberPrice(value)}${
    units ? `\u00A0/${units}` : ''
  }`;
