import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

/* eslint-disable import/no-unresolved */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
/* eslint-enable import/no-unresolved */

import { Typography } from 'antd';
import { roundToPrecision } from 'src/utils/rounding';

import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { MinMaxRangeString } from 'src/components/project/explore/MinMaxRangeString';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import { highlightFilters } from 'src/components/project/explore/charts/chartShared';
import { useKpiUnit } from 'src/components/project/explore/filters/KpiFilter';
import falsyNot0 from 'src/components/utils/falsyNot0';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import { useConciergeContext } from 'src/components/concierge/ConciergeContext';
import { getKpiAnalytics } from 'src/Query/warehouse';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title);

const options = {
  indexAxis: 'x',
  elements: {
    bar: {
      borderWidth: 1
    }
  },
  responsive: true,
  plugins: {
    legend: false,
    title: false,
    tooltip: false
  },
  scales: {
    x: {
      display: true,
      ticks: {
        stepSize: 1
      },
      grid: {
        display: false
      },
      beginAtZero: false
    },
    y: {
      display: true,
      beginAtZero: false
    }
  }
};

function SpecSupplierCapabilityBarChart({
  decimalPrecision,
  filters,
  kpiCode,
  kpiFilters,
  max,
  min,
  step,
  excludeMyData
}) {
  const { min: specMinFilter, max: specMaxFilter } =
    kpiFilters?.[kpiCode] || {};
  const { data: kpiData, isLoading } = useDebouncedQuery({
    queryKey: ['warehouse', 'kpi-analytics', filters],
    queryFn: () => getKpiAnalytics(filters)
  });

  const supplier = useConciergeContext(['explore', 'insights', 'company']);

  const loadingStateClass = useLoadingStateClass(isLoading);

  const specData = kpiData?.[kpiCode] ?? undefined;

  const defaultUnits = useKpiUnit(kpiCode);
  const kpiUnit =
    specData?.units || kpiFilters?.[kpiCode]?.units || defaultUnits;

  const extendedValues = {};
  (specData?.values || []).forEach((v) => {
    if (!(v.company in extendedValues)) {
      extendedValues[v.company] = {
        mins: [],
        maxs: []
      };
    }

    const hasMin = typeof v.min === 'number';
    const hasMax = typeof v.max === 'number';

    // if range has no min or max, to up to the min or max respectively, as an open range
    extendedValues[v.company].mins.push(
      hasMin ? v.min : hasMax ? max * 0.8 : min
    );
    extendedValues[v.company].maxs.push(
      hasMax ? v.max : hasMin ? v.min * 1.2 : max
    );
  });

  // Because of the data spread, we need a minimum visible range spread of smaller ranges or data points
  const minVisibleRangeSpread = (max - min) * 0.04; // 4% of the range

  const computedOptions = useMemo(
    () => ({
      ...options,
      rangeStart: specMinFilter,
      rangeEnd: specMaxFilter,
      step,
      scales: {
        ...options.scales,
        y: {
          ...options.scales.y,
          max,
          min,
          ticks: {
            stepSize: step
          }
        }
      }
    }),
    [max, min, options, specMaxFilter, specMinFilter, step]
  );

  const highlightSelectedFilters = useMemo(
    () => ({
      id: 'highlightSelectedFilters',
      afterDatasetsDraw: highlightFilters
    }),
    [step, isLoading]
  );

  const supplierIds = Object.keys(extendedValues).filter(
    (id) => !excludeMyData || id !== supplier?.uuid
  );
  const labels = supplierIds.map((_, i) => i + 1);

  const values = [];

  supplierIds.forEach((supplierId) => {
    let rangeStart = Math.min(...extendedValues[supplierId].mins);
    let rangeEnd = Math.max(...extendedValues[supplierId].maxs);

    if (Math.abs(rangeEnd - rangeStart) < minVisibleRangeSpread) {
      const mid = (rangeEnd + rangeStart) / 2;
      rangeEnd = mid + minVisibleRangeSpread / 2;
      rangeStart = mid - minVisibleRangeSpread / 2;
    }

    values.push([
      roundToPrecision(rangeStart, decimalPrecision),
      roundToPrecision(rangeEnd, decimalPrecision)
    ]);
  });

  // Sorting based in the middle of the bar: (min + max) / 2
  values.sort((a, b) => (a[0] + a[1]) / 2 - (b[0] + b[1]) / 2);

  const data = {
    labels,
    datasets: [
      {
        label: 'Value range',
        data: values,
        borderSkipped: false,
        borderRadius: 2,
        borderColor: 'rgba(184, 197, 215, 0.50)',
        backgroundColor: 'rgba(184, 197, 215, 0.50)'
      }
    ]
  };

  const noValues = falsyNot0(specMaxFilter) && falsyNot0(specMinFilter);

  const kpiObj = useMetaNumericalProperty(kpiCode);

  return (
    <ExplorePanel className={loadingStateClass} pad>
      <Typography.Title
        level={3}
        style={{ margin: '0 0 20px', fontSize: '18px' }}
      >
        {kpiObj ? kpiObj.name : kpiCode}
        {kpiCode ? ' by Suppliers' : ''}
      </Typography.Title>

      {/* TODO: extract to a separate component, common to charts */}
      <p className="chart-info-label">
        <span className="chart-info-bullet">&nbsp;</span>
        {supplier ? (
          <span className="mr-xxs">
            {supplier.name}{' '}
            <strong>{kpiObj ? kpiObj.name : kpiCode} Value</strong>
          </span>
        ) : null}
        {!noValues ? (
          <>
            <MinMaxRangeString
              max={specMaxFilter}
              min={specMinFilter}
              kpiUnit={kpiUnit}
            />
            {!supplier && `: `}
          </>
        ) : null}
        {!supplier && (
          <>
            <strong>{supplierIds.length} Suppliers</strong>{' '}
            {noValues ? '' : 'have values that match your range'}
          </>
        )}
      </p>

      <p className="axis-label">
        {kpiCode} Values{kpiUnit ? ` (${kpiUnit})` : ''}
      </p>
      <Bar
        options={computedOptions}
        data={data}
        plugins={[highlightSelectedFilters]}
      />
      <p className="axis-label right-aligned">Suppliers</p>
    </ExplorePanel>
  );
}

SpecSupplierCapabilityBarChart.propTypes = {
  decimalPrecision: PropTypes.number.isRequired,
  filters: PropTypes.object,
  kpiCode: PropTypes.string.isRequired,
  kpiFilters: PropTypes.object,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  excludeMyData: PropTypes.bool
};

export default SpecSupplierCapabilityBarChart;
