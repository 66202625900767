import React from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { getUnits } from 'src/Query';
import { patchUser } from 'src/Mutation';
import { useUser } from 'src/utils/authentication';
import { Select } from 'antd';

const unitMap = {
  inverse_concentration: 'dl/g',
  pressure: 'MPa',
  unitless: '',
  length_per_time: 'g/10min',
  temperature: '°C',
  hardness_scale: 'Rockwell E',
  hardness: 'Mohr',
  length: 'm',
  time: 's',
  weight: 'kg',
  percent: '%',
  electric_potential_per_length: 'kV/cm',
  density: 'g/ml',
  energy: 'kJ',
  energy_per_area: 'ft-lb/in^2',
  concentration: 'ppm',
  inverse_temperature: '/°C',
  flow: 'g/10min',
  electrical_potential_per_length: 'kV/mm',
  electrical_potential: 'V',
  electrical_resistance_length: 'Ω cm'
};

export function defaultUnitForType(type) {
  return unitMap[type] || '';
}

export function useAvailableUnits() {
  const { data } = useQuery(['units'], () => getUnits());

  if (!data) return null;

  return data;
}

export function useAvailableUnitsForType(type) {
  const { data } = useQuery(['units'], () => getUnits());

  if (!data) return [];

  if (!type || !data[type]) {
    return [];
  }

  return data[type];
}

export function usePreferredUnits(type) {
  const { data: user } = useUser();

  return type ? user?.preferences?.units[type] : user?.preferences;
}

export function PreferredUnits({ type }) {
  const units = usePreferredUnits(type);

  return units;
}
PreferredUnits.propTypes = {
  type: PropTypes.string
};

export function UnitSelect({ unitType, onChange, invalidateQueries }) {
  const {
    data: user,
    isRefetching: isLoadingUser,
    refetch: refetchUser
  } = useUser();

  const availableUnits = useAvailableUnitsForType(unitType);

  const queryClient = useQueryClient();
  const { mutate, isMutating } = useMutation({
    mutationFn: patchUser,
    onSuccess: () => {
      refetchUser();
      queryClient.invalidateQueries({
        queryKey: ['numerical-properties']
      });

      if (Array.isArray(invalidateQueries)) {
        invalidateQueries.forEach((queryKey) =>
          queryClient.invalidateQueries({
            queryKey
          })
        );
      }
    },
    onError: (e) => {
      window.console.error('Error updating user', e);
    }
  });

  if (!user) return null;

  return (
    <Select
      removeIcon
      dropdownStyle={{ width: 100 }}
      disabled={isMutating || isLoadingUser || !availableUnits}
      value={user.settings?.unit_preferences?.[unitType]}
      onChange={(val) => {
        mutate({
          id: user.id,
          settings: {
            ...(user.settings || {}),
            unit_preferences: {
              ...(user.settings?.unit_preferences || {}),
              [unitType]: val
            }
          }
        });

        if (typeof onChange === 'function') onChange(val);
      }}
      options={
        availableUnits?.map((unit) => ({
          label: unit,
          value: unit
        })) || []
      }
    />
  );
}
UnitSelect.propTypes = {
  unitType: PropTypes.string,
  onChange: PropTypes.func,
  invalidateQueries: PropTypes.func
};
