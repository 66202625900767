import { CloseCircleFilled, PlusSquareFilled } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Switch
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from '@uidotdev/usehooks';

import { getPopulatedNumericalPropertyGroups } from 'src/Query';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import { useAvailableUnitsForType } from 'src/utils/units';
import { useUser } from 'src/utils/authentication';
import { patchUser } from 'src/Mutation';
import useMetaNumericalPropertyTestConditions from 'src/hooks/useMetaNumericalPropertyTestConditions';
import BookmarkIcon from 'src/components/icons/Bookmark';

export default function KpiFilter() {
  // We store active KPIs in concierge ctx state so we can restore them
  const [exploreCtx, setExploreCtx] = useConciergeContextState(['explore']);

  // New filter boxes with no KPI selected don't need to be restored, so we store
  // in local state only
  const [unspecifiedKpiFilters, setUnspecifiedKpiFilters] = useState([]);

  return (
    <div className="kpi-filter">
      {exploreCtx?.kpis
        ? Object.keys(exploreCtx?.kpis).map((kpiCode) => (
            <SingleKpiFilter
              key={kpiCode}
              kpiCode={kpiCode}
              onClose={() => {
                const activeKpisClone = { ...exploreCtx.kpis };
                delete activeKpisClone[kpiCode];

                const activeKpiFiltersClone = {
                  ...(exploreCtx.filters?.kpis || {})
                };
                delete activeKpiFiltersClone[kpiCode];

                setExploreCtx({
                  ...exploreCtx,
                  filters: {
                    ...exploreCtx.filters,
                    kpis: activeKpiFiltersClone
                  },
                  kpis: activeKpisClone
                });
              }}
            />
          ))
        : null}

      {unspecifiedKpiFilters.map((tempId) => (
        <ExplorePanel className="kpi-filter--single" key={tempId} pad={12}>
          <KpiSelect
            onSelect={(kpiCode, i) => {
              const kpiInitialValue = {
                absolute: kpiCode === 'PCRBV'
              };

              // Add active KPI filter box
              const activeKpisClone = { ...(exploreCtx?.kpis || {}) };
              activeKpisClone[kpiCode] = kpiInitialValue;

              // Ensure the new box is toggled on by default
              const activeKpiFiltersClone = {
                ...(exploreCtx?.filters?.kpis || {})
              };
              activeKpiFiltersClone[kpiCode] = kpiInitialValue;

              setExploreCtx({
                ...exploreCtx,
                filters: {
                  ...(exploreCtx?.filters || {}),
                  kpis: activeKpiFiltersClone
                },
                kpis: activeKpisClone
              });

              // Remove KpiSelect box
              const unspecifiedKpiFiltersClone = [...unspecifiedKpiFilters];
              unspecifiedKpiFiltersClone.splice(i, 1);
              setUnspecifiedKpiFilters(unspecifiedKpiFiltersClone);
            }}
          />
        </ExplorePanel>
      ))}

      <Button
        type="link"
        onClick={() => {
          const tempId = `id${Math.random().toString(16).slice(2)}`;
          setUnspecifiedKpiFilters([...unspecifiedKpiFilters, tempId]);
        }}
      >
        <PlusSquareFilled /> Add Specification
      </Button>
    </div>
  );
}

export const KPIS_WITH_TEST_CONDITIONS = ['MFI', 'T1', 'T2', 'T3', 'T4'];

function SingleKpiFilter({ kpiCode, onClose }) {
  const [kpiValues, setKpiValues] = useConciergeContextState([
    'explore',
    'kpis',
    kpiCode
  ]);
  const debouncedKpiValues = useDebounce(kpiValues, 600);

  const [allKpiFilters, setAllKpiFilters] = useConciergeContextState([
    'explore',
    'filters',
    'kpis'
  ]);

  const [selectedKpiFilter, setSelectedKpiFilter] = useConciergeContextState([
    'explore',
    'filters',
    'kpis',
    kpiCode
  ]);

  const kpiObj = useMetaNumericalProperty(kpiCode);

  const [form] = Form.useForm();
  const allowNull = Form.useWatch('allow_null', form);

  const filterOnKpi = useMemo(() => !!selectedKpiFilter, [selectedKpiFilter]);
  const setFilterOnKpi = (newFilterOnKpi) => {
    if (!newFilterOnKpi) {
      const allKpiFiltersClone = { ...allKpiFilters };

      delete allKpiFiltersClone[kpiCode];

      setAllKpiFilters(allKpiFiltersClone);
    } else setSelectedKpiFilter(kpiValues);
  };

  useEffect(() => {
    if (!filterOnKpi) {
      const allKpiFiltersClone = { ...allKpiFilters };

      delete allKpiFiltersClone[kpiCode];

      setAllKpiFilters(allKpiFiltersClone);
    } else setSelectedKpiFilter(kpiValues);
  }, []);

  useEffect(() => {
    if (filterOnKpi) setSelectedKpiFilter(kpiValues);
  }, [debouncedKpiValues]);

  // Update form values when switching projects
  useEffect(() => {
    form.setFieldsValue({
      allow_null: false,
      min: null,
      max: null,
      test_conditions: null,
      ...kpiValues
    });
  }, [kpiValues]);

  return (
    <ExplorePanel className="kpi-filter--single" pad={12}>
      <button
        onClick={onClose}
        type="button"
        className="bare kpi-filter--remove"
      >
        <CloseCircleFilled />
      </button>
      <div className="kpi-filter--active">
        <Form
          onValuesChange={(_, values) => {
            setKpiValues({
              ...values,
              unit_type: kpiObj?.unit_type,
              absolute: kpiCode === 'PCRBV'
            });
          }}
          form={form}
          initialValues={{
            allow_null: false,
            min: null,
            max: null,
            test_conditions: null,
            ...kpiValues
          }}
        >
          <Row wrap={false} gutter={[4, 4]}>
            <Col>
              <div
                className={`kpi-filter--required ${
                  allowNull ? '' : 'is-required'
                }`}
              >
                <Form.Item noStyle name="allow_null" valuePropName="checked">
                  <Checkbox>
                    <BookmarkIcon />
                  </Checkbox>
                </Form.Item>
              </div>
            </Col>
            <Col style={{ flexGrow: 1 }}>
              <KpiName codeOrUuid={kpiCode} />
            </Col>
            <Col>
              <Switch
                size="small"
                value={filterOnKpi}
                onChange={setFilterOnKpi}
              />
            </Col>
          </Row>
          <Row gutter={[4, 0]}>
            <Col className="minmax" span={9}>
              <div>Min</div>
              <Form.Item noStyle name="min">
                <InputNumber
                  value={kpiValues?.min}
                  size="small"
                  type="number"
                  placeholder="No min"
                  controls={false}
                />
              </Form.Item>
            </Col>
            <Col className="minmax" span={9}>
              <div>Max</div>
              <Form.Item noStyle name="max">
                <InputNumber
                  value={kpiValues?.max}
                  size="small"
                  type="number"
                  placeholder="No max"
                  controls={false}
                />
              </Form.Item>
            </Col>
            <Col className="minmax" span={6}>
              <div>Units</div>
              <Form.Item noStyle name="units">
                <KpiUnitSelect codeOrUuid={kpiCode} />
              </Form.Item>
            </Col>
            {KPIS_WITH_TEST_CONDITIONS.includes(kpiCode) && (
              <Col className="minmax" span={24}>
                <div>Test Conditions</div>
                <Form.Item noStyle name="test_conditions">
                  <KpiTestConditionsSelect kpiCode={kpiCode} />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </div>
    </ExplorePanel>
  );
}
SingleKpiFilter.propTypes = {
  kpiCode: PropTypes.string,
  onClose: PropTypes.func
};

export function KpiSelect({
  filter = null,
  style = {},
  onSelect,
  selectProps = {}
}) {
  const { data: populatedNumericalPropertyGroups, isLoading } = useQuery(
    ['populated-numerical-property-groups'],
    () => getPopulatedNumericalPropertyGroups()
  );

  if (isLoading) return null;

  return (
    <div style={style}>
      <Select
        style={{ width: '100%' }}
        showSearch
        optionFilterProp="filterProp"
        options={(populatedNumericalPropertyGroups || [])
          .map((group) => {
            const options = (
              typeof filter === 'function'
                ? group.meta_numerical_properties.filter((kpi) =>
                    filter(group, kpi)
                  )
                : group.meta_numerical_properties
            ).map((mnp) => ({
              label: mnp.name,
              value: mnp.code,
              filterProp: `${group.name} ${mnp.name} ${mnp.code}`
            }));

            // Used to filter out groups without options
            if (options.length === 0) return null;

            return {
              label: group.name,
              options
            };
          })
          // Excluding groups with no options after filtering
          .filter((group) => group !== null)}
        onSelect={onSelect}
        placeholder="Select a spec..."
        {...selectProps}
      />
    </div>
  );
}
KpiSelect.propTypes = {
  filter: PropTypes.func,
  onSelect: PropTypes.func,
  style: PropTypes.object,
  selectProps: PropTypes.object
};

export function KpiName({ codeOrUuid }) {
  const kpiObj = useMetaNumericalProperty(codeOrUuid);

  return !kpiObj ? codeOrUuid : kpiObj.name;
}
KpiName.propTypes = {
  codeOrUuid: PropTypes.string
};

export function useKpiUnit(codeOrUuid) {
  const kpiObj = useMetaNumericalProperty(codeOrUuid);

  return !kpiObj ? null : kpiObj.units;
}
useKpiUnit.propTypes = {
  codeOrUuid: PropTypes.string
};

export function KpiUnitSelect({ codeOrUuid, value, onChange }) {
  const {
    data: user,
    isRefetching: isLoadingUser,
    refetch: refetchUser
  } = useUser();

  const kpiObj = useMetaNumericalProperty(codeOrUuid);
  const availableUnits = useAvailableUnitsForType(kpiObj?.unit_type);

  const queryClient = useQueryClient();
  const { mutate, isMutating } = useMutation({
    mutationFn: patchUser,
    onSuccess: () => {
      refetchUser();
      queryClient.invalidateQueries({
        queryKey: ['numerical-properties']
      });
    },
    onError: (e) => {
      window.console.error('Error updating user', e);
    }
  });

  return (
    <Select
      removeIcon
      suffixIcon={null}
      dropdownStyle={{ width: 100 }}
      size="small"
      disabled={isMutating || isLoadingUser || !kpiObj || !availableUnits}
      value={value}
      onChange={(val) =>
        onChange(val) &&
        mutate({
          id: user.id,
          settings: {
            ...(user.settings || {}),
            unit_preferences: {
              ...(user.settings?.unit_preferences || {}),
              [kpiObj.unit_type]: val
            }
          }
        })
      }
      options={
        availableUnits?.map((unit) => ({
          label: unit,
          value: unit
        })) || []
      }
    />
  );
}
KpiUnitSelect.propTypes = {
  codeOrUuid: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export function KpiTestConditionsSelect({ kpiCode, ...props }) {
  const testConditions = useMetaNumericalPropertyTestConditions(kpiCode);

  return (
    <Select
      disabled={!testConditions.length}
      dropdownStyle={{ minWidth: 200 }}
      size="small"
      defaultValue=""
      options={[
        { label: 'Any', value: '' },
        ...(testConditions.map((tc) => ({
          label: tc,
          value: tc
        })) || [])
      ]}
      {...props}
    />
  );
}
KpiTestConditionsSelect.propTypes = {
  kpiCode: PropTypes.string
};
