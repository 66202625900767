import { Button, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { Link, useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import RfqIcon from 'src/components/icons/RfqIcon';
import SendIcon from 'src/components/icons/SendIcon';
import ReplyIcon from 'src/components/icons/ReplyIcon';
import QuoteIcon from 'src/components/icons/QuoteIcon';
import RfqModal from 'src/components/project/search/RfqModal';

export default function OutreachResults({
  quotesCount = 0,
  quotesPercent = 0,
  respondedAmount = 0,
  respondedPercent = 0,
  rfqSentCount = 0,
  rfqSentPercent = 0
}) {
  const [searchParams] = useSearchParams();
  const projectUuid = searchParams.get('project');

  const [rfqApproved, setRfqApproved] = useState(false);

  return (
    <Row
      className="search-results--supplier-outreach mb-xl"
      gutter={[16, 0]}
      align="stretch"
    >
      <Col span="6">
        <ExplorePanel pad={21}>
          <Typography.Paragraph className="panel-title" strong>
            <RfqIcon /> RFQ
          </Typography.Paragraph>
          <Typography.Paragraph className="body">
            Once approved, your RFQ will be sent to your selected suppliers.
          </Typography.Paragraph>
          <div className="bottom">
            <RfqModal onOk={() => setRfqApproved(true)} />
          </div>
        </ExplorePanel>
      </Col>
      <Col span="6">
        <ExplorePanel pad={21}>
          <Typography.Paragraph className="panel-title" strong>
            <SendIcon /> Sent
          </Typography.Paragraph>
          <Typography.Paragraph className="body">
            The number of suppliers that have been sent your RFQ.
          </Typography.Paragraph>
          <div className="bottom">
            <span className="highlight">{rfqApproved ? rfqSentCount : 0}</span>
            <span className="secondary">
              {rfqApproved ? rfqSentPercent : 0}%
            </span>
          </div>
        </ExplorePanel>
      </Col>
      <Col span="6">
        <ExplorePanel pad={21}>
          <Typography.Paragraph className="panel-title" strong>
            <ReplyIcon /> Responses
          </Typography.Paragraph>
          <Typography.Paragraph className="body">
            Suppliers who have responded to our outreach.
          </Typography.Paragraph>
          <div className="bottom">
            <span className="highlight">
              {rfqApproved ? respondedAmount : 0}
            </span>
            <span className="secondary">
              {rfqApproved ? respondedPercent : 0}%
            </span>
          </div>
        </ExplorePanel>
      </Col>
      <Col span="6">
        <ExplorePanel dark={rfqApproved} pad={21}>
          <Typography.Paragraph className="panel-title" strong>
            <QuoteIcon /> Quotes
          </Typography.Paragraph>
          <Typography.Paragraph className="body">
            The number of quotes we have received from our outreach.
          </Typography.Paragraph>
          <div className="bottom">
            {rfqApproved ? (
              <Link to={`/project/proposal?project=${projectUuid}`}>
                <Button
                  disabled={!rfqSentCount}
                  htmlType="button"
                  type="primary"
                >
                  View Quotes
                </Button>
              </Link>
            ) : (
              <>
                <span className="highlight">
                  {rfqApproved ? quotesCount : 0}
                </span>
                <span className="secondary">
                  {rfqApproved ? quotesPercent : 0}%
                </span>
              </>
            )}
          </div>
        </ExplorePanel>
      </Col>
    </Row>
  );
}

OutreachResults.propTypes = {
  quotesCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quotesPercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  respondedAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  respondedPercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rfqSentCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rfqSentPercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
