import React, { useEffect, useMemo, useState } from 'react';
import PropType from 'prop-types';
import { Group } from '@visx/group';
import { scaleLinear, scaleSqrt } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { format } from '@visx/vendor/d3-format';
import { LegendLinear } from '@visx/legend';
import { Popover, Tag } from 'antd';
import './Heatmap.less';
import useMetaPropertyOptionNameFromCode from 'src/hooks/useMetaPropertyOptionNameFromCode';
import useMetaNumericalPropertyNameFromCode from 'src/hooks/useMetaNumericalPropertyNameFromCode';
import prettyNumber from 'src/components/utils/prettyNumber';
import AdminConsoleLink from 'src/components/navigate/AdminConsoleLink';
import prettyId from 'src/components/utils/prettyId';
import useIsConcierge from 'src/hooks/useIsConcierge';

const zeroDecimalFormat = format('.0f');

const layerColors = [
  ['#cbcbcb', '#292929'],
  ['#d6e0f8', '#275fe7'],
  ['#f7bde0', '#ec1e9a'],
  ['#c9f2d8', '#24a206'],
  ['#f5e1c5', '#e99824'],
  ['#f0c7f3', '#e227f1'],
  ['#e5e2c1', '#b7ab16']
];

const emptyColor = '#f0f0f0';

// const defaultMargin = { top: 10, left: 20, right: 20, bottom: 110 };
const defaultMargin = { top: 30, left: 70, right: 20, bottom: 60 };

function TdsList({ uuids }) {
  return (
    <ol className="list-tds-unstyled">
      {(uuids || []).map((uuid) => (
        <li key={uuid}>
          <AdminConsoleLink app="api" type="technicaldatasheet" uuid={uuid}>
            TDS #{prettyId(uuid)}
          </AdminConsoleLink>
        </li>
      ))}
    </ol>
  );
}

TdsList.propTypes = {
  uuids: PropType.arrayOf(PropType.string)
};

function Heatmap({
  width,
  height,
  data,
  kpiX,
  kpiY,
  targetX,
  targetY,
  unitsX,
  unitsY,
  events = false,
  margin = defaultMargin
}) {
  const [layerActive, setLayerActive] = useState([]);
  const [activeCellValue, setActiveCellValue] = useState();

  const isConcierge = useIsConcierge();

  useEffect(
    () => data && setLayerActive(new Array(data.length).fill(true)),
    [data]
  );

  // the order of the layers from the API is the footprint, so that smaller
  // layers are on top
  // but to show deterministic order for colors and legend
  // we calculate a new order based on the properrty information of the layer:
  //      primary sort order is the property type and secondary is the property value
  //
  // layerOrder is a mapping from the index of the layer in `data` to its new order
  // inverseLayerOrder is a mapping from the new order back to the index of `data`

  const [layerOrder, inverseLayerOrder] = useMemo(() => {
    if (Array.isArray(data)) {
      const indexes = [...Array(data.length).keys()]; // [0, 1, ... n-1]
      const layerKeys = data.map(
        (layer) => `${layer.layer_property || ''}/${layer.label}`
      );
      indexes.sort((ia, ib) => layerKeys[ia].localeCompare(layerKeys[ib]));
      return [
        indexes,
        indexes.map((_, i) => indexes.findIndex((v) => v === i)) // reverse the map
      ];
    }
    return [[], []];
  }, [data]);

  const layerColorScales = useMemo(() => {
    if (Array.isArray(data)) {
      return data.map((layer, index) => {
        const colors = layerColors[inverseLayerOrder[index]] || layerColors[0];
        return scaleSqrt({
          range: [colors[0], colors[1]],
          domain: [0, Math.max(...layer.heatmap.flat())]
        });
      });
    }
    return [];
  }, [data]);

  const emptyCell = { color: emptyColor, value: 0, label: '' };
  const mergedHeatmap = useMemo(() => {
    if (data) {
      const baseHeatmap = data[0].heatmap;
      const layers = data.length;
      const cols = baseHeatmap[0].length;
      const rows = baseHeatmap.length;
      const heatmap = Array.from({ length: rows }, () =>
        Array(cols).fill(emptyCell)
      );
      for (let r = 0; r < rows; r++) {
        for (let c = 0; c < cols; c++) {
          for (let l = layers - 1; l >= 0; l--) {
            if (layerActive[l]) {
              const layer = data[l];
              const cell = layer.heatmap[r][c];
              const uuids = layer.uuids ? layer.uuids?.[r]?.[c] : [];
              if (cell) {
                heatmap[r][c] = {
                  color: layerColorScales[l](cell),
                  value: cell,
                  label: layer.label,
                  total: layer.tds_count,
                  uuids: uuids || []
                };
                break;
              }
            }
          }
        }
      }
      return heatmap;
    }
    return undefined;
  }, [data, layerActive]);

  const layerNames = useMetaPropertyOptionNameFromCode(
    data?.map((layer) => layer.label)
  );

  const kpiNames = useMetaNumericalPropertyNameFromCode([kpiX, kpiY]);

  const layerNamesWithBase = [...(layerNames || ['Base'])];
  layerNamesWithBase[0] = 'Base';

  if (!data) return undefined;

  const baseLayer = data ? data[0] : { heatmap: [] };
  const { heatmap } = baseLayer;
  const cellsX = data ? heatmap[0].length : 0;
  const cellsY = data ? heatmap.length : 0;
  const minX = baseLayer.min_x;
  const minY = baseLayer.min_y;
  const maxX = baseLayer.max_x;
  const maxY = baseLayer.max_y;

  // bounds

  const xMaxPixel = width - margin.left - margin.right;
  const yMaxPixel = height - margin.bottom - margin.top;

  const cellWidth = xMaxPixel / cellsX;
  const cellHeight = yMaxPixel / cellsY;

  // scales for cell index to pixel position
  const xScale = scaleLinear({
    domain: [0, cellsX],
    range: [0, xMaxPixel]
  });
  const yScale = scaleLinear({
    domain: [0, cellsY],
    range: [yMaxPixel - cellHeight, -cellHeight]
  });

  // scales for pixel position to cell index
  const xScaleInverse = scaleLinear({
    range: [0, cellsX],
    domain: [0, xMaxPixel]
  });
  const yScaleInverse = scaleLinear({
    range: [0, cellsY],
    domain: [yMaxPixel - cellHeight, -cellHeight]
  });

  // scales for pixel position to physical units
  const xScaleUnits = scaleLinear({
    domain: [minX, maxX],
    range: [0, xMaxPixel]
  });
  const yScaleUnits = scaleLinear({
    domain: [minY, maxY],
    range: [yMaxPixel, 0]
  });

  // scales for cell index to physical units
  const xScaleUnitsCell = scaleLinear({
    domain: [0, cellsX],
    range: [minX, maxX]
  });
  const yScaleUnitsCell = scaleLinear({
    domain: [0, cellsY],
    range: [minY, maxY]
  });

  const pickTargetXY = (targetArray, index, scale) =>
    Array.isArray(targetArray) && Number.isFinite(targetArray[index])
      ? scale(targetArray[index])
      : null;

  const targetXmin = pickTargetXY(targetX, 0, xScaleUnits);
  const targetXmax = pickTargetXY(targetX, 1, xScaleUnits);
  const targetYmin = pickTargetXY(targetY, 0, yScaleUnits);
  const targetYmax = pickTargetXY(targetY, 1, yScaleUnits);

  const gap = 2;

  const mouseMove = (e) => {
    try {
      const { offsetX, offsetY } = e.nativeEvent;
      const cellX = Math.trunc(xScaleInverse(offsetX - margin.left));
      const cellY = Math.ceil(yScaleInverse(offsetY - margin.top));
      const value = mergedHeatmap[cellY]?.[cellX];
      if (value?.value)
        setActiveCellValue({
          ...value,
          valueY: yScaleUnitsCell(cellY),
          valueX: xScaleUnitsCell(cellX)
        });
      else if (cellX >= 0 && cellY >= 0)
        setActiveCellValue({
          value: 0,
          valueY: yScaleUnitsCell(cellY),
          valueX: xScaleUnitsCell(cellX)
        });
      else setActiveCellValue(undefined);
    } catch (ex) {
      window.console.log('mouseMove error', ex, e, mergedHeatmap);
    }
  };

  const mouseLeave = () => {
    setActiveCellValue(undefined);
  };
  return width < 10 ? null : (
    <div
      className="heatmap"
      style={{ width: width + margin.left + margin.right + 50 }}
    >
      <svg
        width={width}
        height={height}
        onMouseMove={mouseMove}
        onMouseLeave={mouseLeave}
      >
        <Group top={margin.top} left={margin.left}>
          <AxisLeft
            scale={yScaleUnits}
            top={0}
            left={0}
            label={`${kpiNames[1]} (${unitsY})`}
            strokeWidth={0}
            numTicks={8}
            stroke="#1b1a1e"
            tickTextFill="#1b1a1e"
            labelClassName="left-horiz"
          />
          <AxisBottom
            scale={xScaleUnits}
            top={yMaxPixel}
            label={`${kpiNames[0]} (${unitsX})`}
            strokeWidth={0}
            numTicks={12}
            stroke="#1b1a1e"
            tickTextFill="#1b1a1e"
          />

          {mergedHeatmap.map((row, rowIndex) =>
            row.map((cell, cellIndex) => {
              const cellElement = (
                <rect
                  className="visx-heatmap-rect"
                  width={cellWidth - gap}
                  height={cellHeight - gap}
                  x={xScale(cellIndex)}
                  y={yScale(rowIndex)}
                  fill={cell.color}
                  onClick={() => {
                    if (!events) return;
                    // eslint-disable-next-line
                    alert(JSON.stringify({ rowIndex, cellIndex, cell }));
                  }}
                />
              );
              return (
                <React.Fragment key={`heatmap-rect-${rowIndex}-${cellIndex}`}>
                  {!isConcierge || (!cell.uuids?.length && cellElement)}
                  {isConcierge && cell.uuids?.length > 0 && (
                    <Popover
                      placement="right"
                      trigger="click"
                      content={<TdsList uuids={cell.uuids} />}
                    >
                      {cellElement}
                    </Popover>
                  )}
                </React.Fragment>
              );
            })
          )}
          {targetXmin && (
            <line
              x1={targetXmin}
              x2={targetXmin}
              y1={0}
              y2={yMaxPixel}
              style={{ stroke: 'red', strokeWidth: 1 }}
            />
          )}
          {targetXmax && (
            <line
              x1={targetXmax}
              x2={targetXmax}
              y1={0}
              y2={yMaxPixel}
              style={{ stroke: 'red', strokeWidth: 1 }}
            />
          )}
          {targetYmin && (
            <line
              x1={0}
              x2={xMaxPixel}
              y1={targetYmin}
              y2={targetYmin}
              style={{ stroke: 'red', strokeWidth: 1 }}
            />
          )}
          {targetYmax && (
            <line
              x1={0}
              x2={xMaxPixel}
              y1={targetYmax}
              y2={targetYmax}
              style={{ stroke: 'red', strokeWidth: 1 }}
            />
          )}
        </Group>
      </svg>
      <div className="details">
        <LegendLinear
          scale={layerColorScales[0]}
          labelFormat={(d, i) => (i % 2 === 0 ? zeroDecimalFormat(d) : '')}
          direction="column-reverse"
          itemDirection="row-reverse"
          labelMargin="0 20px 0 0"
          shapeMargin="1px 0 0"
        />
        <div className="cell-details">
          {activeCellValue ? (
            <>
              <div>
                TDS count:{' '}
                <strong>{zeroDecimalFormat(activeCellValue.value)}</strong>
              </div>
              <div style={{ marginTop: 8 }}>
                {kpiX}: {prettyNumber(activeCellValue.valueX)}&nbsp;{unitsX}
              </div>
              <div>
                {kpiY}: {prettyNumber(activeCellValue.valueY)}&nbsp;{unitsY}
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="heatmap-tags">
        {layerOrder.map((orderedIndex, index) => {
          // const originalIndex = inverseLayerOrder[orderedIndex];
          const o = data[orderedIndex];
          const name = layerNamesWithBase[orderedIndex];
          return (
            <Tag.CheckableTag
              key={o.label}
              style={{
                background:
                  layerActive[orderedIndex] && layerColors[index]
                    ? layerColors[index][1]
                    : '#eeeeee'
              }}
              checked={layerActive[orderedIndex]}
              onChange={(checked) => {
                const newValue = [...layerActive];
                newValue[orderedIndex] = checked;
                setLayerActive(newValue);
              }}
            >
              {`${name} (${o.tds_count || 0})`}
            </Tag.CheckableTag>
          );
        })}
      </div>
    </div>
  );
}

Heatmap.propTypes = {
  width: PropType.number,
  height: PropType.number,
  targetX: PropType.array,
  targetY: PropType.array,
  data: PropType.array,
  margin: PropType.object,
  kpiX: PropType.string,
  kpiY: PropType.string,
  unitsX: PropType.string,
  unitsY: PropType.string,
  events: PropType.bool
};

export default Heatmap;
