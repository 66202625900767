import { Empty, Flex, Skeleton, Typography } from 'antd';
import React from 'react';
import { getSupplierCapabilities, getTDSs } from 'src/Query/warehouse';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import Location from 'src/components/project/explore/Location';
import Pricing from 'src/components/project/explore/Pricing';
import Specifications from 'src/components/project/explore/Specifications';

import emptyExplore from 'src/images/exploreEmpty.png';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import Summary from 'src/components/project/explore/summary/ShortSummary';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import BalesExplore from 'src/components/project/explore/BalesExplore';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';

export function ConditionalContent() {
  const filters = useRelevantFiltersForForm();
  const noFilters = Object.keys(filters).length === 0;
  return noFilters ? <EmptyExplore /> : <Content />;
}

export default function Content() {
  const [activeKpiFilters] = useConciergeContextState([
    'explore',
    'filters',
    'kpis'
  ]);
  const filters = useRelevantFiltersForForm();
  const [form] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);

  const filterSupplier = { ...filters, limit: 1, group_by_company: 'true' };
  const {
    data: filteredSupplierRecords,
    isLoading: isLoadingFilteredSupplierRecords
  } = useDebouncedQuery(
    ['warehouse', 'supplier-capability', filterSupplier],
    () => getSupplierCapabilities(filterSupplier)
  );

  const filterTotalSupplier = { limit: 1, group_by_company: 'true' };
  const {
    data: totalSupplierRecords,
    isLoading: isLoadingTotalSupplierRecords
  } = useDebouncedQuery(
    ['warehouse', 'supplier-capability', filterTotalSupplier],
    () => getSupplierCapabilities(filterTotalSupplier)
  );

  const { data: filteredRecords, isLoading: isLoadingFilteredRecords } =
    useDebouncedQuery({
      queryKey: ['warehouse', 'technical-data-sheet', filters],
      queryFn: () => getTDSs(filters),
      keepPreviousData: true
    });

  const { data: totalRecords, isLoading: isLoadingTotalRecords } =
    useDebouncedQuery({
      queryKey: ['warehouse', 'technical-data-sheet'],
      queryFn: () => getTDSs(),
      keepPreviousData: true
    });

  const isLoading = isLoadingFilteredRecords || isLoadingTotalRecords;

  if (isLoading && !filteredRecords && !totalRecords) return <Skeleton />;

  const noFilters = filteredRecords?.count === totalRecords?.count;

  if (noFilters) return <EmptyExplore />;

  if (form === 'bales') return <BalesExplore />;

  return (
    <>
      <Flex className="mb-lg" justify="space-between">
        <Typography.Paragraph className="mb-0" style={{ alignSelf: 'center' }}>
          We found{' '}
          <span data-testid="recordsCount">
            {filteredRecords ? prettyNumberRound(filteredRecords.count) : '-'}
          </span>{' '}
          matching records and{' '}
          {filteredSupplierRecords
            ? prettyNumberRound(filteredSupplierRecords.count)
            : '-'}{' '}
          prospective suppliers out of{' '}
          {totalSupplierRecords
            ? prettyNumberRound(totalSupplierRecords.count)
            : '-'}
          .
        </Typography.Paragraph>
      </Flex>

      <Summary />

      <Location />

      <Specifications
        description="Filtered specification data is compared against our database of supplier records. View a summary of the comparison and where else you could source material at the desired specification. Investigate further by viewing detailed curves of our dataset and supplier ranges."
        kpiTabs={activeKpiFilters}
        filters={filters}
      />

      <Pricing />
    </>
  );
}

function EmptyExplore() {
  return (
    <Empty image={emptyExplore} description="">
      <Typography.Title level={2}>Explore what's possible</Typography.Title>
      <Typography.Paragraph>
        Leverage Circular's database of over 50,000 records to get a real
        picture of what is possible with PCR.
      </Typography.Paragraph>
    </Empty>
  );
}
