import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RfqReadOnly from 'src/components/rfq/RfqReadOnlyWarehouse';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';

export default function RfqModal({ onOk }) {
  const [open, setOpen] = useState();
  const filters = useRelevantFiltersForForm();

  return (
    <>
      <Button
        disabled={!filters}
        onClick={() => setOpen(true)}
        htmlType="button"
      >
        View RFQ
      </Button>
      <Modal
        width={520}
        title="Approve Digital RFQ"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => {
          if (onOk) onOk();
          setOpen(false);
        }}
        okText="Approve & Send"
        className="rfq-modal"
      >
        <RfqReadOnly
          rfqData={filters}
          id="58f136c4-33a8-49bf-a32f-bbe7a557f489"
        />
      </Modal>
    </>
  );
}
RfqModal.propTypes = {
  onOk: PropTypes.func
};
