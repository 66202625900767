import { useQuery } from '@tanstack/react-query';
import { getProperties } from 'src/Query';

// it is important to make this a constant or otherwise the hook returns a new instance of an empty array
// on every call
const DEFAULT_LOADING = [];

export default function useMetaProperty(propertyNameOrCode, optionNameOrCode) {
  const { data: metaProperties = DEFAULT_LOADING } = useQuery({
    queryKey: ['properties'],
    queryFn: () => getProperties()
  });

  if (!propertyNameOrCode) return metaProperties;

  const property = metaProperties?.find(
    (mp) =>
      mp.code === propertyNameOrCode ||
      mp.name.toLowerCase() === propertyNameOrCode.toLowerCase()
  );

  if (optionNameOrCode && property && Array.isArray(property.options)) {
    return property.options.find(
      (option) =>
        option.code === optionNameOrCode ||
        option.value.toLowerCase() === optionNameOrCode.toLowerCase()
    );
  }

  return property;
}

export function MetaPropertyName({ property, optionValue, lowercase }) {
  const propertyOrOption = useMetaProperty(property, optionValue);

  if (!propertyOrOption) return null;

  const val = propertyOrOption.name || propertyOrOption.value;

  if (lowercase) return val.toLowerCase();

  return val;
}
