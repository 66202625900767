import { fetch } from 'src/utils/client';

const deleteKeys = (params, keys) => {
  if (!params) return params;
  const result = { ...params };
  keys.forEach((key) => {
    delete result[key];
  });
  return result;
};

export const requestChange = (rfqId, change) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/request-for-quote/${rfqId}/user-feedback/`,
    {
      method: 'POST',
      body: JSON.stringify(change)
    }
  );

export const approveRFQ = (rfqId) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/request-for-quote/${rfqId}/`, {
    method: 'PATCH',
    body: JSON.stringify({
      status: 'approved'
    })
  });

export const submitRFQ = (data) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/request-for-quote/`, {
    method: 'POST',
    body: typeof data === 'string' ? data : JSON.stringify(data)
  });

export const generateRfqDefaults = ({ uuid, properties }) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/request-for-quote/${uuid}/numerical-property-defaults/`,
    {
      method: 'POST',
      body:
        typeof properties === 'string' ? properties : JSON.stringify(properties)
    }
  );

export const generateTdsDefaults = ({ uuid, properties }) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/technical-data-sheet/${uuid}/numerical-property-defaults/`,
    {
      method: 'POST',
      body:
        typeof properties === 'string' ? properties : JSON.stringify(properties)
    }
  );

export const patchRFQ = (data) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/request-for-quote/${data.uuid}/`, {
    method: 'PATCH',
    body: JSON.stringify(data)
  });

export const submitOrPatchRFQ = (data) =>
  data.uuid ? patchRFQ(data) : submitRFQ(data);

export const submitExpertInformationRequest = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/expert-information-request/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });

export const submitCarbonCreditInformationRequest = (body) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/carbon-credit-information-request/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  );

export const submitSupplyChainInformationRequest = (body) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/supply-chain-information-request/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  );

export const submitDeal = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/deal/`, {
    method: 'POST',
    body: JSON.stringify(body)
  });

export const joinDeal = (id) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/deal/${id}/join/`, {
    method: 'POST'
  });

export const patchDeal = (data) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/deal/${data.uuid}/`, {
    method: 'PATCH',
    body: JSON.stringify(data)
  });

export const patchDealTestLab = (data) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/deal/${data.uuid}/test-lab/`, {
    method: 'PATCH',
    body: JSON.stringify(data)
  });

export const patchDealSupply = (data) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/deal-supply/${data.uuid}/`, {
    method: 'PATCH',
    body: JSON.stringify(data)
  });

export const createTds = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/technical-data-sheet/`, {
    method: 'POST',
    body: JSON.stringify(body)
  });

export const updateTds = (body) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/technical-data-sheet/${body.uuid}/`,
    {
      method: 'PATCH',
      body: JSON.stringify(body)
    }
  );

export const createOrUpdateTds = (body) =>
  (body.uuid && updateTds(body)) || createTds(body);

export const deleteTds = (uuid) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/technical-data-sheet/${uuid}/`, {
    method: 'DELETE'
  });

export const updateCompany = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/company/${body.uuid}/`, {
    method: 'PATCH',
    body: JSON.stringify(body)
  });

export const createCompany = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/company/`, {
    method: 'POST',
    body: JSON.stringify(body)
  });

export const createDealSampleResult = (body) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/deal/${body.dealId}/sample-result/`,
    {
      method: 'POST',
      body: JSON.stringify(body)
    }
  );

export const createSesameLink = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/sesame-link/`, {
    method: 'POST',
    body: JSON.stringify(body)
  });

export const createSalesflareLink = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/company/${params.uuid}/salesflare/${params.salesflareId}/`,
    {
      method: 'POST'
    }
  );

export const createOpportunityLink = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/request-for-quote/${params.uuid}/opportunity/${params.opportunityId}/`,
    {
      method: 'POST'
    }
  );

export const createCompanySalesflare = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/company/salesflare/${params.salesflareId}/`,
    {
      method: 'POST'
    }
  );

export const plasticsUncoveredLead = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/reports/plastics-uncovered/`, {
    method: 'POST',
    body: JSON.stringify(body)
  });

export const evaluateSupplierList = ({ params, body }) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/company/evaluate/${
      params.rfqId
    }/?${new URLSearchParams(deleteKeys(params, ['rfqId'])).toString()}`,
    {
      method: 'POST',
      body: JSON.stringify(body)
    }
  );

export const evaluateSupplier = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/company/${params.uuid}/evaluate/${
      params.rfqId
    }/?${new URLSearchParams(
      deleteKeys(params, ['rfqId', 'uuid'])
    ).toString()}`,
    {
      method: 'POST'
    }
  );

export const createOnbording = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/onboarding/`, {
    method: 'POST',
    body: JSON.stringify(body)
  });

export const updateOnbording = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/onboarding/${body.uuid}/`, {
    method: 'PATCH',
    body: JSON.stringify(body)
  });

export const createOrUpdateOnbording = (body) =>
  (body.uuid && updateOnbording(body)) || createOnbording(body);

export const inviteUser = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/user/invite/`, {
    method: 'POST',
    body: JSON.stringify(body)
  });

export const patchDealSampleResults = (body) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/deal/${body.dealId}/sample-result/${body.sampleResultId}/`,
    {
      method: 'PATCH',
      body: JSON.stringify(body)
    }
  );

export const patchUser = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/user/${body.id}/`, {
    method: 'PATCH',
    body: JSON.stringify(body)
  });

export const createPriceLog = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/price-log/`, {
    method: 'POST',
    body: JSON.stringify(body)
  });

export const createProject = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/project/`, {
    method: 'POST',
    body: JSON.stringify(body)
  });

export const updateProject = (body) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/project/${body.uuid}/`, {
    method: 'PATCH',
    body: JSON.stringify(body)
  });

export const createOrUpdateProject = (body) =>
  (body.uuid && updateProject(body)) || createProject(body);

export const deleteProject = (uuid) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/project/${uuid}/`, {
    method: 'DELETE'
  });
