import { useMarketPriceLatest } from 'src/Query/marketPrice';
import { EXCELLENT, FAIR, GOOD, NO_DATA } from 'src/components/GoodnessRating';

const usePricingConfidenceRating = ({ filters }) => {
  const { form_code__in, ...filtersWithoutForm } = filters || {};
  const formFilter = {
    form_code__in: (form_code__in &&
      form_code__in.length > 0 &&
      form_code__in) || ['resin_pellets']
  };

  const { isLoading: isLoadingVirgin, price: virginPrice } =
    useMarketPriceLatest({
      ...filtersWithoutForm,
      ...formFilter,
      virgin: true
    });
  const { isLoading: isLoadingResin, price: pcrPrice } = useMarketPriceLatest({
    ...filtersWithoutForm,
    ...formFilter,
    virgin: false,
    circular: true
  });

  if (isLoadingVirgin || isLoadingResin) return NO_DATA;

  if (!virginPrice || !pcrPrice) return NO_DATA;

  const virginPriceMid = (virginPrice.high + virginPrice.low) / 2;
  const pcrPriceMid = (pcrPrice.high + pcrPrice.low) / 2;

  if (pcrPriceMid > virginPriceMid) return FAIR;
  if ((virginPriceMid - pcrPriceMid) / pcrPrice < 0.03) return GOOD;
  if ((virginPriceMid - pcrPriceMid) / pcrPrice < 0.08) return GOOD;
  return EXCELLENT;
};

export default usePricingConfidenceRating;
