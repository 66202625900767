import { Row, Col, Skeleton, Typography } from 'antd';
import React from 'react';
import { getSupplierCapabilities, getTDSs } from 'src/Query/warehouse';

import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import { useMapPageWarehouse } from 'src/pages/maps/MapPageWarehouse';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import SupplierSummary from 'src/components/project/explore/summary/SupplierSummary';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import BalesPriceSummary from 'src/components/project/explore/summary/BalesPriceSummary';

export default function BalesExplore() {
  const filters = useRelevantFiltersForForm();

  const { MapComponent, activeRecords } = useMapPageWarehouse({
    useMapProps: { theme: 'light_new' },
    className: 'bales-explore-map'
  });

  const filterSupplier = { ...filters, limit: 1, group_by_company: 'true' };
  const {
    data: filteredSupplierRecords,
    isLoading: isLoadingFilteredSupplierRecords
  } = useDebouncedQuery(
    ['warehouse', 'supplier-capability', filterSupplier],
    () => getSupplierCapabilities(filterSupplier)
  );

  const filterTotalSupplier = { limit: 1, group_by_company: 'true' };
  const {
    data: totalSupplierRecords,
    isLoading: isLoadingTotalSupplierRecords
  } = useDebouncedQuery(
    ['warehouse', 'supplier-capability', filterTotalSupplier],
    () => getSupplierCapabilities(filterTotalSupplier)
  );

  const { data: filteredRecords, isLoading: isLoadingFilteredRecords } =
    useDebouncedQuery({
      queryKey: ['warehouse', 'technical-data-sheet', filters],
      queryFn: () => getTDSs(filters),
      keepPreviousData: true
    });

  const { data: totalRecords, isLoading: isLoadingTotalRecords } =
    useDebouncedQuery({
      queryKey: ['warehouse', 'technical-data-sheet'],
      queryFn: () => getTDSs(),
      keepPreviousData: true
    });

  const isLoading = isLoadingFilteredRecords || isLoadingTotalRecords;

  if (isLoading && !filteredRecords && !totalRecords) return <Skeleton />;

  return (
    <div className="bales-explore">
      <Typography.Title level={3}>Bales Explore</Typography.Title>
      <div className="mb-lg" justify="space-between">
        <Typography.Paragraph className="mb-0" style={{ alignSelf: 'center' }}>
          We found{' '}
          <span data-testid="recordsCount">
            {filteredRecords ? prettyNumberRound(filteredRecords.count) : '-'}
          </span>{' '}
          matching records and{' '}
          {filteredSupplierRecords
            ? prettyNumberRound(filteredSupplierRecords.count)
            : '-'}{' '}
          prospective suppliers out of{' '}
          {totalSupplierRecords
            ? prettyNumberRound(totalSupplierRecords.count)
            : '-'}
          .
        </Typography.Paragraph>
      </div>
      <Row gutter={[16, 32]} align="stretch">
        <Col span="24">
          <ExplorePanel style={{ height: 500 }}>{MapComponent}</ExplorePanel>
        </Col>
        <Col span="12">
          <SupplierSummary activeRecords={activeRecords} />
        </Col>
        <Col span="12">
          <BalesPriceSummary />
        </Col>
      </Row>
    </div>
  );
}
