import { Col, Row, Space, Typography } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';

import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import {
  Box,
  BoxColumn,
  useSummaryData
} from 'src/components/project/explore/summary/helpers';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import { currencyValuePrice } from 'src/utils/currency';

export default function PriceSummary({ compact }) {
  const { data, isLoading } = useSummaryData();

  const loadingClass = useLoadingStateClass(isLoading);
  const [projectSettings] = useConciergeContextState([
    'explore',
    'projectSettings'
  ]);

  const [rows, cols] = useMemo(() => {
    const rowList = [];
    const colList = [];
    (projectSettings?.summary_options?.prices || []).forEach((price) => {
      if (!compact || price.summary) {
        const row = rowList[price.row] || [];
        row.push({ ...price });
        rowList[price.row] = row;
        if (price.col_label && !colList[price.col || 0]) {
          colList[price.col || 0] = price.col_label;
        }
      }
    });
    rowList.forEach((row) => row.sort((a, b) => (a.col || 0) - (b.col || 0)));
    if (data?.prices) {
      rowList.forEach((row) =>
        row.forEach((price) => {
          const priceData = data.prices.find(
            (p) => p?.market_price_ticker_id === price?.ticker
          );
          // eslint-disable-next-line
          price.mid = priceData
            ? (priceData.high_usdlb + priceData.low_usdlb) / 2
            : null;
          // eslint-disable-next-line
          price.source = price.source || priceData?.source?.toUpperCase();
        })
      );
    }

    return [rowList, colList];
  }, [projectSettings, data]);

  const labelColumnSpan = 8;
  const boxWidth = 280;
  return (
    <ExplorePanel className={loadingClass} pad>
      <Space direction="vertical" size={18}>
        <Row gutter={[8, 0]}>
          <Col span={labelColumnSpan}>
            <Typography.Text strong>
              Price{!compact ? ' Summary' : ''}
            </Typography.Text>
          </Col>
          {cols.map((col) => (
            <BoxColumn
              width={boxWidth / cols.length}
              span={(24 - labelColumnSpan) / cols.length}
              key={col}
            >
              <Typography.Text strong>{col}</Typography.Text>
            </BoxColumn>
          ))}
        </Row>
        {rows.map((row, index) => (
          <Row
            key={index}
            className="explore-summary--kpi-row"
            gutter={[16, 0]}
            align="middle"
          >
            <Col span={labelColumnSpan}>
              <Typography.Text strong>{row[0]?.label}</Typography.Text>
              <br />
              <Typography.Text type="secondary">
                {row[0]?.source || ''}
              </Typography.Text>
            </Col>
            {row.map((price) => (
              <BoxColumn
                span={(24 - labelColumnSpan) / row.length}
                key={price.ticker}
                width={boxWidth / row.length}
              >
                <Box
                  className={
                    price.type === 'circular' && price.mid
                      ? 'success'
                      : undefined
                  }
                >
                  <Typography.Text strong>
                    {price.mid
                      ? currencyValuePrice(price.mid, 'USD', 'lb')
                      : '-'}
                  </Typography.Text>
                </Box>
              </BoxColumn>
            ))}
          </Row>
        ))}
      </Space>
    </ExplorePanel>
  );
}

PriceSummary.propTypes = {
  compact: PropTypes.bool
};
