import React from 'react';
import { ReactComponent as Icon } from './svg/bookmark.svg';
import SvgIconComponent from './SvgIconComponent';

function BookmarkIcon() {
  return (
    <SvgIconComponent>
      <Icon />
    </SvgIconComponent>
  );
}

export default BookmarkIcon;
