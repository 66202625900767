import {
  DownloadOutlined,
  RightOutlined,
  SearchOutlined,
  TableOutlined
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  Button,
  Col,
  Input,
  Radio,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  message
} from 'antd';
import { saveAs } from 'file-saver';
import React, { useEffect, useMemo, useState } from 'react';
import { getSupplierCapabilities, getTdsCsv } from 'src/Query/warehouse';
import useGetWarehouseTdss from 'src/Query/useGetWarehouseTdss';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import MapIcon from 'src/components/icons/MapIcon';
import { sortableColumnTitle } from 'src/pages/concierge/ProposalBuilder/columns/usePbColumns';
import { locationRender } from 'src/pages/rfq/proposal/supply/SupplyRoute';
import MapPageWarehouse from 'src/pages/maps/MapPageWarehouse';
import TdsQuantity from 'src/components/project/search/TdsQuantity';
import CompareModal from 'src/components/project/search/CompareModal';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import EditTdsModal from 'src/pages/supplier/TechnicalDataSheet';
import numericRange from 'src/components/utils/numericRange';
import useIsConcierge from 'src/hooks/useIsConcierge';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import OutreachResults from 'src/components/project/search/OutreachResults';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';

const DEFAULT_PAGE_SIZE = 10;

export default function SearchContent() {
  const filters = useRelevantFiltersForForm();
  const [kpis] = useConciergeContextState(['explore', 'filters', 'kpis']);

  const isConcierge = useIsConcierge();

  const [view, setView] = useState('table');

  const [selectedRows, setSelectedRows] = useState([]);

  // Pagination shouldn't propagate to Explore, so we store in local state only
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pagination, setPagination] = useState({
    page: 1
  });
  const [paginationSuppliers, setPaginationSuppliers] = useState({
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
    page: 1
  });

  // Open text search shouldn't propagate to Explore, so we store in local state only
  const [search, setSearch] = useState();
  const [orderBy, setOrderBy] = useState();
  const [tdsBeingEdited, setTdsBeingEdited] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const fields = [
    'tds_id',
    'form',
    'form_code',
    'type',
    'type_code',
    'company_id',
    'company_name',
    'region_code',
    'region',
    'sku',
    'capacity_lbs',
    'supply_capacity_lbs'
  ];

  const agg_fields = ['city', 'state', 'country'];

  const completeFilters = {
    ...filters,
    ...pagination,
    fields,
    agg_fields,
    page_size: pageSize,
    search,
    ...(orderBy ? { order_by: orderBy } : {})
  };

  const { data: filteredRecords, isLoading: isLoadingFilteredRecords } =
    useGetWarehouseTdss(completeFilters);

  const { data: totalSupplierRecords } = useQuery(
    [
      'warehouse',
      'supplier-capability',
      { limit: 1, group_by_company: 'true' }
    ],
    () => getSupplierCapabilities({ limit: 1, group_by_company: 'true' })
  );

  const supplierFilters = {
    ...filters,
    ...paginationSuppliers,
    page_size: pageSize,
    order_by: '-capacity_lbs',
    group_by_company: 'true'
  };
  const { data: filteredSupplierRecords } = useDebouncedQuery(
    ['warehouse', 'supplier-capability', supplierFilters],
    () => getSupplierCapabilities(supplierFilters)
  );

  const tdsRecordCount = filteredRecords?.count || 0;
  const supplierRecordCount = filteredSupplierRecords?.count || 0;

  const addSuppliersToTable =
    filteredRecords && filteredSupplierRecords && tdsRecordCount < pageSize;

  const activePagination = addSuppliersToTable
    ? paginationSuppliers
    : pagination;

  const totalRecords = addSuppliersToTable
    ? (supplierRecordCount || 0) + (tdsRecordCount || 0)
    : tdsRecordCount;

  const changePagination = (page, page_size) => {
    if (addSuppliersToTable && page_size > tdsRecordCount) {
      setPaginationSuppliers({
        offset: page === 1 ? 0 : tdsRecordCount + (page - 1) * page_size,
        page,
        // not needed for pagination on the endpoint, but useful for tracking the state of the table
        limit: page_size
      });
    } else {
      setPagination({ page });
    }
    setPageSize(page_size);
  };

  const supplierRecordsToInclude =
    activePagination.page > 1 ? pageSize : pageSize - tdsRecordCount;

  const suppliers =
    addSuppliersToTable && filteredSupplierRecords.results
      ? filteredSupplierRecords.results.slice(0, supplierRecordsToInclude)
      : [];

  const extraRecordCount = suppliers?.length || 0;

  const rowIsSupplier = (index) =>
    addSuppliersToTable &&
    (activePagination.page > 1 || index >= pageSize - extraRecordCount);

  const tableData = addSuppliersToTable
    ? activePagination.page === 1
      ? [...(filteredRecords?.results || []), ...suppliers] || []
      : suppliers
    : filteredRecords?.results || [];

  const kpiColumns = useMemo(() => {
    const kpiCodes = kpis ? Object.keys(kpis) : [];

    return kpiCodes
      .map((kpiCode) => {
        const kpiKey = `kpi_${kpiCode}`;

        return {
          title: sortableColumnTitle(kpiCode, `${kpiKey}`),
          sorter: true,
          dataIndex: `${kpiKey}.code`,
          sortKey: `${kpiKey}`,
          key: kpiCode,
          render: (_, tdsObj) => {
            if (!tdsObj[`${kpiKey}.code`]) return null;

            const tdsKpiMin = tdsObj[`${kpiKey}.min`];
            const tdsKpiMax = tdsObj[`${kpiKey}.max`];
            const tdsKpiUnits = tdsObj[`${kpiKey}.units`];

            return numericRange({
              min: tdsKpiMin,
              max: tdsKpiMax,
              empty: '–',
              rangeRender: (min, max) => `${min} - ${max} ${tdsKpiUnits}`,
              noMinRender: (v) => `${v} or - ${tdsKpiUnits}`,
              noMaxRender: (v) => `${v} or + ${tdsKpiUnits}`,
              singleValueRender: (v) => `${v} ${tdsKpiUnits}`
            });
          }
        };
      })
      .filter((col) => col !== null);
  }, [kpis]);

  useEffect(() => {
    setSelectedRows([]);
  }, [filters]);

  const [rfqApproved, setRfqApproved] = useState();

  const download = () => {
    const allFilters = {
      ...filters,
      fields,
      agg_fields,
      all: 'true'
    };
    getTdsCsv(allFilters)
      .then((response) => response.text())
      .then((text) => {
        const blob = new Blob([text], { type: 'text/csv;charset=utf-8' });
        saveAs(
          blob,
          `technical-data-sheet-search-${dayjs().format(
            'YYYY-MM-DD-HH-mm' || ''
          )}.csv`
        );
      })
      .catch((error) =>
        messageApi.error(`Error downloading CSV file [${error.message}]`)
      );
  };

  const quotesCount = 0;
  const quotesPercent = 0;
  const respondedAmount = tdsRecordCount / 2;
  const respondedPercent = tdsRecordCount
    ? (100 * respondedAmount) / tdsRecordCount
    : 0;
  const rfqSentCount = tdsRecordCount;
  const rfqSentPercent = 100;

  return (
    <div className="search-results">
      {contextHolder}
      <Typography.Title level={4}>Search Results</Typography.Title>
      <Typography.Paragraph>
        We found{' '}
        <span data-testid="recordsCount">
          {filteredRecords ? prettyNumberRound(filteredRecords.count) : '-'}
        </span>{' '}
        matching records and{' '}
        {filteredSupplierRecords
          ? prettyNumberRound(filteredSupplierRecords.count)
          : '-'}{' '}
        prospective suppliers out of{' '}
        {totalSupplierRecords
          ? prettyNumberRound(totalSupplierRecords.count)
          : '-'}
        .
      </Typography.Paragraph>
      <OutreachResults
        quotesCount={quotesCount}
        quotesPercent={quotesPercent}
        respondedAmount={respondedAmount}
        respondedPercent={respondedPercent}
        rfqSentCount={rfqSentCount}
        rfqSentPercent={rfqSentPercent}
      />
      <Row className="search-results--controls" justify="space-between">
        <Col>
          <Input
            size="small"
            placeholder="Search prospects"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <Space direction="horizontal" size={8}>
            <Button onClick={download} size="small">
              <DownloadOutlined />
            </Button>
            <CompareModal selectedRows={selectedRows} />
            <Radio.Group
              onChange={(e) => setView(e.target.value)}
              value={view}
              size="small"
            >
              <Radio.Button value="table">
                <TableOutlined />
              </Radio.Button>
              <Radio.Button value="map">
                <MapIcon />
              </Radio.Button>
            </Radio.Group>
          </Space>
        </Col>
      </Row>
      {tdsBeingEdited && (
        <EditTdsModal
          open={!!tdsBeingEdited}
          showCompany
          showAdminLinks
          tdsId={tdsBeingEdited}
          hideModal={() => setTdsBeingEdited(null)}
        />
      )}

      {view === 'table' ? (
        <Table
          className="search-results--table"
          // scroll={{
          //   scrollToFirstRowOnChange: true,
          //   x: 'scroll'
          // }}
          loading={isLoadingFilteredRecords}
          rowKey="id"
          columns={[
            {
              title: sortableColumnTitle('Supplier name', 'company_name'),
              sorter: true,
              sortKey: 'company_name',
              dataIndex: 'company_name',
              fixed: 'left',
              width: 400,
              render: (companyName, tdsObj, index) => {
                let tag = null;

                if (rfqApproved && !rowIsSupplier(index)) {
                  if (!(index % 2)) tag = <Tag color="blue">Responded</Tag>;

                  if ([0, 3].includes(index))
                    tag = <Tag color="green">Quote</Tag>;
                }

                return (
                  <Row justify="space-between" wrap={false} gutter={[16, 16]}>
                    <Col>
                      <span className="company">
                        <a
                          href={`/supplier/${tdsObj.company_id}`}
                          title={`${tdsObj.company_name} profile`}
                          target="_blank"
                        >
                          {companyName}
                        </a>
                        {tdsObj.sku ? (
                          <>
                            {' '}
                            (
                            <Typography.Text italic>
                              {tdsObj.sku}
                            </Typography.Text>
                            )
                          </>
                        ) : null}
                        &nbsp;
                        {isConcierge && (
                          <Button
                            type="bare"
                            style={{ padding: 0, height: 16 }}
                            onClick={() => setTdsBeingEdited(tdsObj.tds_id)}
                          >
                            <RightOutlined />
                          </Button>
                        )}
                      </span>
                      <span className="subcontent">
                        {locationRender(tdsObj)}
                      </span>
                    </Col>
                    <Col>{tag}</Col>
                  </Row>
                );
              }
            },
            {
              title: sortableColumnTitle('Type', 'type'),
              sorter: true,
              sortKey: 'type_code',
              dataIndex: 'type'
            },
            {
              title: sortableColumnTitle('Form', 'form'),
              sorter: true,
              sortKey: 'form_code',
              dataIndex: 'form'
            },
            {
              title: sortableColumnTitle('Cap. (lbs)', 'capacity_lbs'),
              sorter: true,
              dataIndex: 'capacity_lbs',
              render: (_, tdsObj) => <TdsQuantity tds={tdsObj} />
            },
            ...kpiColumns
          ]}
          dataSource={tableData}
          pagination={{
            current: activePagination.page,
            pageSize,
            total: totalRecords,
            onChange: changePagination,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 25, 50],
            showTotal: (total, [from, to]) =>
              `Displaying items ${from} - ${to} of ${totalRecords} total`
          }}
          onChange={(_pagination, _filters, sorter) => {
            if (
              !['ascend', 'descend'].includes(sorter?.order) ||
              (!sorter.column.sortKey &&
                typeof sorter.column.dataIndex !== 'string')
            ) {
              setOrderBy(null);
            } else {
              setOrderBy(
                `${sorter.order === 'descend' ? '-' : ''}${
                  sorter.column.sortKey || sorter.column.dataIndex
                }`
              );
            }
          }}
          rowSelection={{
            type: 'checkbox',
            onChange: (_, newSelectedRows) => setSelectedRows(newSelectedRows),
            preserveSelectedRowKeys: true,
            selectedRowKeys: selectedRows.map(({ id }) => id)
          }}
          rowClassName={(record, index) =>
            rowIsSupplier(index) ? 'supplier-row' : 'tds-row'
          }
          // scroll={{
          //   scrollToFirstRowOnChange: true,
          //   x: 'scroll'
          // }}
          // sticky
        />
      ) : (
        <MapPageWarehouse />
      )}
    </div>
  );
}
