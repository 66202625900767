import { Col, Row, Space, Typography } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { Box, BoxColumn } from 'src/components/project/explore/summary/helpers';
import prettyNumber from 'src/components/utils/prettyNumber';

const countSuppliers = (records) => {
  if (!records || !records.length) return 0;

  return records.reduce((sum, { suppliers }) => sum + suppliers, 0);
};

const aggregateCapacity = (records) => {
  if (!records || !records.length) return 0;

  const totalCapacity = records.reduce(
    (sum, { total_capacity_lbs }) => sum + total_capacity_lbs,
    0
  );

  return prettyNumber(totalCapacity);
};

export default function SupplierSummary({ activeRecords }) {
  const boxWidth = 150;
  const labelColumnSpan = 8;

  const [verifiedRecords, verifiedCapacity] = useMemo(() => {
    if (!Array.isArray(activeRecords)) return [0, 0];

    return activeRecords.reduce(
      ([recordsSum, capacityLbsSum], { verified, verified_capacity_lbs }) => [
        recordsSum + verified,
        capacityLbsSum + verified_capacity_lbs
      ],
      [0, 0]
    );
  }, [activeRecords]);

  return (
    <ExplorePanel pad>
      <Space direction="vertical" size={18}>
        <Row gutter={[16, 0]}>
          <Col style={{ marginRight: 'auto' }}>
            <Typography.Text strong>Supplier Summary</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 0]} align="middle" wrap={false}>
          <Col span={labelColumnSpan}></Col>
          <BoxColumn width={boxWidth}>
            <Typography.Text strong align="middle">
              Total
            </Typography.Text>
          </BoxColumn>
          <BoxColumn width={boxWidth}>
            <Typography.Text strong>Est. Capacity (lbs)</Typography.Text>
          </BoxColumn>
        </Row>
        <Row gutter={[16, 0]} align="middle" wrap={false}>
          <Col span={labelColumnSpan}>
            <Typography.Text strong align="middle">
              Verified Suppliers
            </Typography.Text>
          </Col>
          <BoxColumn width={boxWidth}>
            <Box className="neutral  verified-suppliers-count">
              <Typography.Text strong>{verifiedRecords}</Typography.Text>
            </Box>
          </BoxColumn>
          <BoxColumn width={boxWidth}>
            <Box className="neutral  verified-capacity">
              <Typography.Text strong>
                {prettyNumber(verifiedCapacity)}
              </Typography.Text>
            </Box>
          </BoxColumn>
        </Row>
        <Row gutter={[16, 0]} align="middle" wrap={false}>
          <Col span={labelColumnSpan}>
            <Typography.Text strong align="middle">
              All Suppliers
            </Typography.Text>
          </Col>
          <BoxColumn width={boxWidth}>
            <Box className="neutral total-suppliers-count">
              <Typography.Text strong>
                {countSuppliers(activeRecords)}
              </Typography.Text>
            </Box>
          </BoxColumn>
          <BoxColumn width={boxWidth}>
            <Box className="neutral total-capacity">
              <Typography.Text strong>
                {aggregateCapacity(activeRecords)}
              </Typography.Text>
            </Box>
          </BoxColumn>
        </Row>
      </Space>
    </ExplorePanel>
  );
}
SupplierSummary.propTypes = {
  activeRecords: PropTypes.array
};
