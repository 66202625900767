import { useQuery } from '@tanstack/react-query';
import { getRegionalAnalytics } from 'src/Query/warehouse';
import {
  EXCELLENT,
  FAIR,
  GOOD,
  POOR,
  VERY_GOOD
} from 'src/components/GoodnessRating';
import { useMemo } from 'react';
import { reduceRegionalAnalytics } from 'src/utils/aggregations';

const tdsCountRatingRules = [
  // S0 rules, including spec filters
  {
    rating: EXCELLENT,
    fn: (s0Count) => s0Count >= 10,
    priority: 1
  },
  {
    rating: VERY_GOOD,
    fn: (s0Count, s0Proportion) =>
      s0Count >= 5 && s0Count <= 9 && s0Proportion >= 0.4,
    priority: 2
  },
  {
    rating: GOOD,
    fn: (s0Count, s0Proportion) =>
      s0Count >= 5 && s0Count <= 9 && s0Proportion < 0.4,
    priority: 3
  },
  {
    rating: FAIR,
    fn: (s0Count) => s0Count >= 3 && s0Count <= 4,
    priority: 4
  },
  {
    rating: POOR,
    fn: (s0Count) => s0Count >= 1 && s0Count <= 2,
    priority: 5
  },
  // S1 rules, excluding spec filters
  {
    rating: EXCELLENT,
    fn: (s0Count, _, s1Count) => s0Count === 0 && s1Count >= 30,
    priority: 6
  },
  {
    rating: VERY_GOOD,
    fn: (s0Count, _, s1Count, s1Proportion) =>
      s0Count === 0 && s1Count >= 15 && s1Count <= 29 && s1Proportion >= 0.4,
    priority: 7
  },
  {
    rating: GOOD,
    fn: (s0Count, _, s1Count, s1Proportion) =>
      s0Count === 0 && s1Count >= 15 && s1Count <= 29 && s1Proportion < 0.4,
    priority: 8
  },
  {
    rating: FAIR,
    fn: (s0Count, _, s1Count) => s0Count === 0 && s1Count >= 7 && s1Count <= 14,
    priority: 9
  },
  {
    rating: POOR,
    fn: (s0Count, _, s1Count) => s0Count === 0 && s1Count <= 6,
    priority: 10
  }
];

const sortByPriority = (rule1, rule2) => rule1.priority - rule2.priority;

const getRatingFromCount = (s0Count, s0Proportion, s1Count, s1Proportion) => {
  const rules = tdsCountRatingRules
    .filter((r) => r.fn(s0Count, s0Proportion, s1Count, s1Proportion))
    .sort(sortByPriority);

  if (rules.length === 0) throw new Error('No rules found for the TDS count');

  return rules[0].rating;
};

const useLocationConfidenceRating = ({ filters }) => {
  const { region_code__in: _regionsFilter = [], ...filtersWithoutRegion } =
    filters || {};

  const { kpis: _kpisFilter = {}, ...filtersWithoutSpecs } = filters || {};

  const {
    kpis: _,
    region_code__in: __,
    ...filtersWithoutRegionAndSpecs
  } = filters || {};

  // S0
  const { data: specAndRegionFilteredRecords } = useQuery({
    queryKey: ['warehouse', 'regional-analytics', filters],
    queryFn: () => getRegionalAnalytics(filters),
    keepPreviousData: true
  });

  // S0 all
  const { data: noRegionRecords } = useQuery({
    queryKey: ['warehouse', 'regional-analytics', filtersWithoutRegion],
    queryFn: () => getRegionalAnalytics(filtersWithoutRegion),
    keepPreviousData: true
  });

  // S1
  const { data: noSpecsRecords } = useQuery({
    queryKey: ['warehouse', 'regional-analytics', filtersWithoutSpecs],
    queryFn: () => getRegionalAnalytics(filtersWithoutSpecs),
    keepPreviousData: true
  });

  // S1 all
  const { data: noSpecNoRegionRecords } = useQuery({
    queryKey: ['warehouse', 'regional-analytics', filtersWithoutRegionAndSpecs],
    queryFn: () => getRegionalAnalytics(filtersWithoutRegionAndSpecs),
    keepPreviousData: true
  });

  // S0 count
  const specAndRegionFilteredRecordCount = useMemo(
    () =>
      (specAndRegionFilteredRecords || []).reduce(reduceRegionalAnalytics, {})
        .tdss || 0,
    [specAndRegionFilteredRecords]
  );

  // S0 all count
  const noRegionRecordCount = useMemo(
    () => (noRegionRecords || []).reduce(reduceRegionalAnalytics, {}).tdss || 0,
    [noRegionRecords]
  );

  // S1 count
  const noSpecsRecordCount = useMemo(
    () => (noSpecsRecords || []).reduce(reduceRegionalAnalytics, {}).tdss || 0,
    [noSpecsRecords]
  );

  // S1 all count
  const noSpecNoRegionRecordCount = useMemo(
    () =>
      (noSpecNoRegionRecords || []).reduce(reduceRegionalAnalytics, {}).tdss ||
      0,
    [noSpecNoRegionRecords]
  );

  const locationSpecificProportion =
    specAndRegionFilteredRecordCount / noRegionRecordCount;

  const noSpecLocationSpecificProportion =
    noSpecsRecordCount / noSpecNoRegionRecordCount;

  return getRatingFromCount(
    specAndRegionFilteredRecordCount,
    locationSpecificProportion,
    noSpecsRecordCount,
    noSpecLocationSpecificProportion
  );
};

export default useLocationConfidenceRating;
