import React from 'react';
import { ReactComponent as Icon } from './svg/marker-small-icon.svg';
import SvgIconComponent from './SvgIconComponent';

function MarkerSmallIcon(props) {
  return (
    <SvgIconComponent {...props}>
      <Icon style={{ height: 26, width: 26 }} />
    </SvgIconComponent>
  );
}

export default MarkerSmallIcon;
