import { Skeleton } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import useGetWarehouseTdss from 'src/Query/useGetWarehouseTdss';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import Specifications from 'src/components/project/explore/Specifications';

const specsDescription =
  'Compare material properties for your product with specifications for similar products in your cohort. ' +
  'Understand where your product falls in the range of values for any given metric in the market and what range of ' +
  'performance your competitive set of suppliers offers.';

export default function ProductInsights({ cohort }) {
  const [exploreCtx] = useConciergeContextState(['explore']);

  const { filters } = cohort;

  const { data, isLoading } = useGetWarehouseTdss(
    {
      ...filters,
      all: 'true'
    },
    {}
  );

  const supplierCount = useMemo(() => {
    if (!data) return 0;
    const supplierIds = new Set(data.map((rTds) => rTds.company_id));
    return supplierIds.size;
  }, [data]);

  if (isLoading || data === undefined) return <Skeleton />;

  return (
    <Specifications
      description={specsDescription}
      filters={filters}
      kpiTabs={exploreCtx?.kpis ?? {}}
      showHeatmap={false}
      showSummary={false}
      subTitle={`Showing ${
        data?.length ?? 0
      } Records from ${supplierCount} Suppliers`}
    />
  );
}

ProductInsights.propTypes = {
  cohort: PropTypes.object
};
