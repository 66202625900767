import React from 'react';
import { List, Row, Typography, Col } from 'antd';
import PropTypes from 'prop-types';

import prettyNumber from 'src/components/utils/prettyNumber';
import numericRange from 'src/components/utils/numericRange';
import useMetaProperty from 'src/hooks/useMetaProperty';
import { KpiName } from 'src/components/project/explore/filters/KpiFilter';

export const compareProperties = (propertyValue1, propertyValue2) => {
  const property1 = propertyValue1?.property || propertyValue1?.meta_property;
  const property2 = propertyValue2?.property || propertyValue2?.meta_property;
  if (property1 && !property2) return -1;
  if (!property1 && property2) return 1;
  if (!property1 && !property2) return 0;
  if (property1.order && !property2.order) return -1;
  if (!property1.order && property2.order) return 1;
  if (!property1.order && !property2.order) return 0;
  return property1.order - property2.order;
};

export default function RfqReadOnly({ rfqData }) {
  const propertyCodeToName = usePropertyCodeToName();

  return (
    <div className="rfq__content">
      <List
        header={
          <Typography.Title level={3}>Material & Quantity</Typography.Title>
        }
      >
        <RfqDetailRow
          label="Material"
          value={
            rfqData.type_code__in
              ? propertyCodeToName('type', rfqData.type_code__in)
              : '(any)'
          }
        />
        <RfqDetailRow
          label="Form"
          value={
            rfqData.form_code__in
              ? propertyCodeToName('form', rfqData.form_code__in)
              : '(any)'
          }
        />
        <RfqDetailRow
          label="Quantity"
          value={prettyNumber(rfqData.quantity__gte)}
        />
        {rfqData?.properties
          ? Object.keys(rfqData.properties).map((propertyCode) => (
              <RfqDetailRow
                key={propertyCode}
                label={propertyCodeToName(propertyCode)}
                value={propertyCodeToName(
                  propertyCode,
                  rfqData.properties[propertyCode]
                )}
              />
            ))
          : null}
      </List>

      {rfqData?.kpis ? (
        <List
          header={<Typography.Title level={3}>Specifications</Typography.Title>}
        >
          {Object.keys(rfqData.kpis).map((kpiCode) => (
            <RfqDetailRow
              key={kpiCode}
              label={<KpiName codeOrUuid={kpiCode} />}
              value={numericRange({
                min: rfqData.kpis[kpiCode]?.min,
                max: rfqData.kpis[kpiCode]?.max,
                empty: '–',
                rangeRender: (min, max) =>
                  `${min} - ${max} ${rfqData.kpis[kpiCode]?.units}`,
                noMinRender: (v) => `${v} or - ${rfqData.kpis[kpiCode]?.units}`,
                noMaxRender: (v) => `${v} or + ${rfqData.kpis[kpiCode]?.units}`,
                singleValueRender: (v) => `${v} ${rfqData.kpis[kpiCode]?.units}`
              })}
            />
          ))}
        </List>
      ) : null}
    </div>
  );
}
RfqReadOnly.propTypes = {
  rfqData: PropTypes.object
};

function RfqDetailRow({ label, value, isOpenText }) {
  return (
    <List.Item>
      {isOpenText ? (
        <Row>
          <Col>
            <Typography.Text strong>{label}</Typography.Text>
          </Col>
          <Col span={24}>{value}</Col>
        </Row>
      ) : (
        <Row wrap={false} justify="space-between">
          <Col>
            <Typography.Text strong>{label}</Typography.Text>
          </Col>
          <Col>
            <Typography.Text>{value}</Typography.Text>
          </Col>
        </Row>
      )}
    </List.Item>
  );
}
RfqDetailRow.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isOpenText: PropTypes.bool
};

function usePropertyCodeToName() {
  const properties = useMetaProperty();

  return (propertyCode, optionCode) => {
    const property = properties.find((prop) => prop.code === propertyCode);

    if (!property) return null;

    if (optionCode) {
      const optionCodes = Array.isArray(optionCode) ? optionCode : [optionCode];

      const optionValues = [];

      optionCodes.forEach((code) => {
        const option = property.options.find((opt) => opt.code === code);

        if (option?.value) optionValues.push(option.value);
      });

      return optionValues.join('\n');
    }
    return property?.name;
  };
}
