import Icon, {
  GlobalOutlined,
  GoldFilled,
  MailFilled,
  PhoneFilled,
  PlusOutlined,
  RocketFilled,
  UserOutlined
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
  Avatar,
  Button,
  Col,
  Modal,
  Row,
  Skeleton,
  Space,
  Typography
} from 'antd';
import React, { useMemo, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import { getCompany } from 'src/Query';
import { ReactComponent as MapMarkerIcon } from 'src/components/icons/svg/mapMakrer.svg';
import { getSupplierCapabilities } from 'src/Query/warehouse';
import prettyNumber from 'src/components/utils/prettyNumber';
import CompanyLocationsMap from 'src/components/company/CompanyLocationsMap';
import ManageIcon from 'src/components/icons/Manage';

import useEditSupplierProfile from 'src/pages/supplier/useEditSupplierProfile';
import { useManageTdsModal } from 'src/pages/supplier/useManageTds';
import useIsCompanyMember from 'src/hooks/useIsCompanyMember';
import SupplierTdss from 'src/components/supplier/SupplierTdss';
import SingleCertification from 'src/components/supplier/SingleCertification';
import ProfileHealth from 'src/components/supplier/ProfileHealth';
import useIsConcierge from 'src/hooks/useIsConcierge';
import { useUser } from 'src/utils/authentication';

export default function SupplierProfile() {
  const { supplierId } = useParams();
  const { data: user } = useUser();
  const companyId = supplierId || user?.company?.uuid;
  const isCompanyMember = useIsCompanyMember(companyId);
  const isConcierge = useIsConcierge();

  const { data } = useQuery(
    ['company', companyId],
    () => getCompany(companyId),
    { keepPreviousData: true }
  );

  const filters = {
    company__in: [companyId],
    source__in:
      isCompanyMember && !isConcierge ? ['supplier_direct'] : undefined
  };
  const { data: capabilityData } = useQuery(
    ['warehouse', 'supplier-capability', filters],
    () => getSupplierCapabilities(filters),
    { keepPreviousData: true }
  );

  const [editProfileIsOpen, setEditProfileIsOpen] = useState();
  const {
    FormComponent: EditProfileForm,
    form,
    ErrorAlert,
    isMutating
  } = useEditSupplierProfile({
    supplierId: companyId,
    hideTdsUpload: true,
    afterSubmit: () => setEditProfileIsOpen(false)
  });

  const { ManageTdsModal, openFunc: openManageTdsModal } = useManageTdsModal({
    companyId,
    additionalTdsParams: {
      send_email: true
    }
  });

  const greatestCapacity = useMemo(() => {
    if (!capabilityData?.results?.length) return null;
    let overallGreatest = 0;

    capabilityData.results.forEach(({ capacity_lbs, supply_capacity_lbs }) => {
      const greatest = Math.max(capacity_lbs || 0, supply_capacity_lbs || 0);
      if (greatest > overallGreatest) overallGreatest = greatest;
    });

    return overallGreatest || null;
  }, [capabilityData]);

  const materialTypes = useMemo(() => {
    if (!capabilityData?.results?.length) return [];
    const types = new Set();

    capabilityData.results.forEach(({ type }) => {
      if (type && !types.has(type)) types.add(type);
    });

    return [...types];
  }, [capabilityData]);

  const summaryItems = useMemo(() => {
    if (!data) return [];

    const items = [];

    if (data.locations?.length) {
      data.locations.forEach((loc) =>
        items.push({
          key: loc.uuid,
          icon: <Icon component={MapMarkerIcon} />,
          text: formatAddress(loc)
        })
      );
    }

    if (data.phone_number) {
      items.push({
        key: 'phone',
        icon: <PhoneFilled />,
        text: data.phone_number
      });
    }

    if (data.email) {
      items.push({
        key: 'email',
        icon: <MailFilled />,
        text: data.email
      });
    }

    if (data.year_established) {
      items.push({
        key: 'year_established',
        icon: <RocketFilled />,
        text: `Founded ${data.year_established} (${Math.round(
          data.years_in_business
        )} years)`
      });
    }

    if (data.website) {
      items.push({
        key: 'website',
        icon: <GlobalOutlined />,
        text: (
          <a href={data.website} title={data.name} target="_blank">
            {data.website}
          </a>
        )
      });
    }

    const capacity = greatestCapacity || data.supply_capacity_lbs;

    if (capacity) {
      items.push({
        key: 'capacity',
        icon: <GoldFilled />,
        text: `${prettyNumber(capacity)} lbs`
      });
    }

    return items;
  }, [data, greatestCapacity]);

  if (!data || !capabilityData) return <Skeleton />;

  return (
    <Row className="supplier-profile" wrap={false}>
      <Modal
        width={817}
        title="Edit Supplier Profile"
        open={editProfileIsOpen}
        onCancel={() => setEditProfileIsOpen(false)}
        okButtonProps={{
          loading: isMutating
        }}
        okText="Save"
        onOk={form.submit}
        destroyOnClose
      >
        {EditProfileForm}
        {ErrorAlert}
      </Modal>
      {ManageTdsModal}
      <Col flex="376px" className="supplier-profile--sidebar">
        <Space direction="vertical" size={32}>
          <Row wrap={false} justify="space-between">
            <Col>
              <Typography.Title level={4}>
                <Avatar size={40} icon={<UserOutlined />} src={data?.avatar} />{' '}
                {data?.name}
              </Typography.Title>
            </Col>
            {isCompanyMember ? (
              <Col>
                <Button
                  htmlType="button"
                  onClick={() => setEditProfileIsOpen(true)}
                  size="small"
                  type="primary"
                >
                  Edit
                </Button>
              </Col>
            ) : null}
          </Row>
          {isCompanyMember ? <ProfileHealth /> : null}
          <div className="supplier-profile--summary">
            <Space direction="vertical" size={8}>
              {summaryItems?.map((item) => (
                <div key={item.key} className="supplier-profile--summary--item">
                  {item.icon} {item.text}
                </div>
              ))}
              {isConcierge && companyId && (
                <div>
                  <ManageIcon />
                  &nbsp;
                  <Link to={`/supplier/insights/${companyId}`}>Insights</Link>
                </div>
              )}
            </Space>
          </div>
          <div className="supplier-profile--types">
            <Typography.Paragraph strong>Material Types</Typography.Paragraph>
            <Row gutter={[8, 8]}>
              {materialTypes?.map((materialType) => (
                <Col key={materialType}>
                  <div className="supplier-profile--types--single">
                    {materialType}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <div className="supplier-profile--location">
            <Typography.Paragraph strong>Locations</Typography.Paragraph>
            <CompanyLocationsMap company={data} />
          </div>
          <div className="supplier-profile--certs">
            <Typography.Paragraph strong>Certs</Typography.Paragraph>
            <Row gutter={[24, 24]}>
              {data?.certifications?.length ? (
                data.certifications.map((cert) => (
                  <Col key={cert.uuid}>
                    <SingleCertification cert={cert} />
                  </Col>
                ))
              ) : (
                <Col>No data</Col>
              )}
            </Row>
          </div>
        </Space>
      </Col>
      <Col flex="1" className="supplier-profile--content">
        <Row
          className="supplier-profile--content--header"
          justify="space-between"
        >
          <Col>
            <Typography.Title level={3}>Supplier Data</Typography.Title>
          </Col>
          {isCompanyMember ? (
            <Col>
              <Button
                onClick={openManageTdsModal}
                type="primary"
                htmlType="button"
                icon={<PlusOutlined />}
              >
                Add Material
              </Button>
            </Col>
          ) : null}
        </Row>
        <SupplierTdss />
      </Col>
    </Row>
  );
}

function formatAddress(loc) {
  let addressStr = ``;

  ['street_one', 'city', 'state', 'zip', 'country'].forEach((key) => {
    if (loc[key]) addressStr += `${loc[key]}, `;
  });

  return addressStr.replace(/,\s$/, '');
}
